import General from "../general/general";

class HomeIntroTabSwiper {
    general = new General;

    homeIntroTabSliderOptions = {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        direction: 'vertical',
        watchOverflow: true
    };

    swiperInit(el) {
        this.homeIntroTabSliderOptions.navigation = {
            nextEl: el.find('.swiper-button-next'),
            prevEl: el.find('.swiper-button-prev')
        };
        this.general.initSwiper(el, this.homeIntroTabSliderOptions);
    };
}

export default HomeIntroTabSwiper;
