import HomeIntro from "./home-intro";
const debounce = require('debounce');

class HomeIntroAnimations {
    homeIntro = new HomeIntro();

    init() {
        const activeListId = $('.home-intro__nav-swiper-holder--visible.js-business-area').attr('id');
        const animateVisibleLinks = this.animateVisibleLinks;
        window.onload = function () {
            animateVisibleLinks();
        };
        animateVisibleLinks();
        this.animatingMobileLinks();
        this.blueBoxTogglers();
        HomeIntroAnimations.toggleLists(activeListId);

        const changePositionOfSeeMoreButton = this.changePositionOfSeeMoreButton;
        changePositionOfSeeMoreButton();

        if ('addEventListener' in window) {
            const handleResize = debounce(function () {
                changePositionOfSeeMoreButton();
            }, 250);
            window.addEventListener('resize', handleResize);
        }
    }

    static toggleLists(listId) {
        const blueBoxLists = $('.js-blue-box-list');

        blueBoxLists.each((i, el) => {
            const self = $(el);

            if (self.attr('list-id') === listId) {
                self.addClass('d-none');
            } else {
                self.removeClass('d-none');
            }
        });
    }

    animateVisibleLinks(businessArea, listId) {
        const animatableLinks = $('.js-business-area.home-intro__nav-swiper-holder--visible .js-intro-link.animatable-intro-el--hidden, .js-business-area.home-intro__nav-holder--visible .js-intro-link.animatable-intro-el--hidden, .home-intro__nav-btn-wrapper--active .js-intro-open-btn.animatable-intro-el--hidden');
        const animatableLinksCount = animatableLinks.length;
        let timeoutDuration = 0;
        let counter = 0;

        function openWheel() {
            const introLink = $('.js-business-area.home-intro__nav-swiper-holder--visible .js-intro-link[bus-area="' + businessArea + '"]');

            introLink.click();
        }

        animatableLinks.each((i, el) => {
            setTimeout(() => {
                counter = i + 1;
                $(el).removeClass('animatable-intro-el--hidden animatable-intro-el--down');

                if (counter === animatableLinksCount && businessArea) {
                    openWheel();
                    HomeIntroAnimations.toggleLists(listId);
                }
            }, timeoutDuration);

            timeoutDuration += 100;
        });
    }

    resetLinksAnimations() {
        const hiddenLinks = $('.js-business-area .js-intro-link, .js-intro-open-btn');

        hiddenLinks.each((i, el) => {
            $(el).addClass('animatable-intro-el--hidden animatable-intro-el--down');
        });
    }

    animatingMobileLinks() {
        const animatableMobileLinks = $('.home-intro__nav--mobile .animatable-intro-el.js-intro-link');
        const animatableMobileLinksClass = '.animatable-intro-el.js-intro-link';
        const navMobileClass = '.home-intro__nav--mobile';
        const navMobileSmallerClass = 'home-intro__nav--smaller';
        const mobileNavListClass = '.home-intro__nav-list';
        const inactive = 'inactive';
        const openMobileMenuBtn = $('.home-intro__nav--mobile .home-intro__nav-btn-wrapper');
        const hideBtn = 'home-intro__nav-btn-wrapper--hidden';

        animatableMobileLinks.on('click', function () {
            const self = $(this);
            openMobileMenuBtn.removeClass(hideBtn);
            self.removeClass(inactive).parent().prependTo(mobileNavListClass);
            self.parent().siblings().find(animatableMobileLinksClass).addClass(inactive);
            $(navMobileClass).animate({ scrollTop: 0 }, 1000);
            $(navMobileClass).addClass(navMobileSmallerClass);
        });

        openMobileMenuBtn.on('click', () => {
            $(navMobileClass).removeClass(navMobileSmallerClass);
            openMobileMenuBtn.addClass(hideBtn);
        });
    }

    changePositionOfSeeMoreButton() {
        const $seeMoreBtn = $($('.home-intro__nav-btn-wrapper').not('.home-intro__nav-btn-wrapper--hidden')[0]);
        const $introLinksContainer = $('.home-intro__nav-swiper-holder--visible .home-intro__nav-swiper-container');
        const $introLinksWrapper = $('.home-intro__nav-swiper-holder--visible .home-intro__nav-swiper-wrapper');
        const availableSpace = ($introLinksContainer.width() - $introLinksWrapper.width()) / 2 - 55;
        if (availableSpace >= $seeMoreBtn.width()) {
            $('.home-intro__nav-btn-wrapper').not('.home-intro__nav-btn-wrapper--hidden').removeClass('home-intro__nav-btn-wrapper--bottom');
        } else {
            $('.home-intro__nav-btn-wrapper').not('.home-intro__nav-btn-wrapper--hidden').addClass('home-intro__nav-btn-wrapper--bottom');
        }
    }

    blueBoxTogglers() {
        const openBoxBtn = $('.js-intro-open-btn');
        const closeBoxBtn = $('.js-intro-close-btn');
        const closeBoxBtnParent = closeBoxBtn.parent();
        const openBoxBtnParent = openBoxBtn.parent();
        const blueBar = $('.js-intro-nav.animatable-intro-el');
        const blueBox = $('.js-blue-box');
        const blueBoxItems = $('.js-blue-box-items');
        const blueBoxLinks = $('.js-blue-box-list li a');
        const introLinks = $('.js-intro-link');

        const highlightClickedLink = this.homeIntro.highlightClickedLink;
        const resetLinksAnimations = this.resetLinksAnimations;
        const animateVisibleLinks = this.animateVisibleLinks;
        const changePositionOfSeeMoreButton = this.changePositionOfSeeMoreButton;

        function toggleBlueBoxClasses(shouldHide) {
            if (shouldHide) {
                blueBox.addClass('animatable-intro-el--hidden');
                blueBoxItems.addClass('animatable-intro-el--hidden animatable-intro-el--down');
            } else {
                blueBox.removeClass('animatable-intro-el--hidden');

                setTimeout(() => {
                    blueBoxItems.removeClass('animatable-intro-el--hidden animatable-intro-el--down');
                }, 300);
            }
        }

        function hideTabs() {
            const tabs = $('.home-intro__tabs--visible');
            
            tabs.removeClass('home-intro__tabs--visible');
        }

        function toggleClasses(clickedBtn) {
            clickedBtn.addClass('animatable-intro-el--hidden');
            clickedBtn.parent().removeClass('home-intro__nav-btn-wrapper--active');

            if (clickedBtn.hasClass('js-intro-open-btn')) {
                if (blueBar.hasClass('home-intro__nav--active')) {
                    blueBar.removeClass('home-intro__nav--active');
                    introLinks.removeClass('inactive');
                }
                hideTabs();
                closeBoxBtnParent.addClass('home-intro__nav-btn-wrapper--active');
                closeBoxBtn.removeClass('animatable-intro-el--hidden');
                blueBar.addClass('animatable-intro-el--up');
                toggleBlueBoxClasses(false);
            } else {
                openBoxBtnParent.addClass('home-intro__nav-btn-wrapper--active');
                openBoxBtn.removeClass('animatable-intro-el--hidden');
                blueBar.removeClass('animatable-intro-el--up');
                toggleBlueBoxClasses(true);
            }
        }

        openBoxBtn.on('click', function (e) {
            const self = $(this);

            e.preventDefault();

            toggleClasses(self);
        });

        closeBoxBtn.on('click', function (e) {
            const self = $(this);

            e.preventDefault();

            toggleClasses(self);
        });

        introLinks.on('click', function () {
            if (!closeBoxBtn.hasClass('animatable-intro-el--hidden')) {
                closeBoxBtn.click();
                blueBar.removeClass('animatable-intro-el--up');

            }
        });

        blueBoxLinks.on('click', function (e) {
            const self = $(this);
            const swiperHolders = $('.js-business-area');
            const targetId = self.attr('target-id');
            const targetBusAreaId = self.attr('bus-area');

            e.preventDefault();

            highlightClickedLink(self, blueBoxLinks);

            setTimeout(() => {
                swiperHolders.each((i, el) => {
                    const self = $(el);
                    const selfId = self.attr('id');

                    if (selfId === targetId) {
                        swiperHolders.removeClass('home-intro__nav-swiper-holder--visible').addClass('home-intro__nav-swiper-holder--hidden');
                        self.removeClass('home-intro__nav-swiper-holder--hidden').addClass('home-intro__nav-swiper-holder--visible');
                        toggleBlueBoxClasses(true);
                        blueBar.removeClass('animatable-intro-el--up');
                        closeBoxBtn.addClass('animatable-intro-el--hidden');
                        closeBoxBtnParent.removeClass('home-intro__nav-btn-wrapper--active');
                        openBoxBtnParent.addClass('home-intro__nav-btn-wrapper--active');
                        resetLinksAnimations();
                        animateVisibleLinks(targetBusAreaId, targetId);
                        blueBoxLinks.removeClass('inactive');
                        changePositionOfSeeMoreButton();
                    }
                });
            }, 500);
        });
    }
}

export default HomeIntroAnimations;
