import IsInViewport from '../lazy-loading/el-is-in-viewport';

class VideoThumbnails {
    thumbnail = $('.video-slider .video-swiper__container .swiper-slide');
    thumbnailSwiper = $('.video-slider .video-swiper__container');
    swiperBtns = $('.video-slider .modal-content .swiper-nav-button');
    video = $('.video-slider .product-gallery-swiper-video .product-gallery-swiper__slide');
    isInViewport = new IsInViewport();
    init() {
        const loadThumbs = this.loadThumbs;
        this.isInViewport.init(this.thumbnail);
        setTimeout(function () {
            $(window).on('resize scroll', function() {
                loadThumbs();
            });
        }, 250);
        loadThumbs();
        this.loadVideos();
        this.openModal();
        this.closeModal();
    }
    loadThumbs() {
        const thumbnail = $('.video-slider .video-swiper__container .swiper-slide');
        const videoEl = $(`<video> <source src=""></video>`);
        const imgEl = $(`<div class="video-slider__thumbnail"></div>`);
        thumbnail.each(function () {
            const imgBtmGradient = $('.img-bottom-gradient');
            const videoData = $(this).attr('video-data');
            const customImage = $(this).attr('custom-image');
            const isVisible = $(this).attr('data-visible');
            const childEl = $(this).find('.img-bottom-gradient')[0].children.length;

            $(this).attr('yt-data');
            const YTData = $(this).attr('yt-data');

            if (videoData && isVisible && childEl < 1) {
                if (customImage) {
                    imgEl.appendTo($(this).find(imgBtmGradient))
                        .css('background-image', `url(${customImage})`);
                } else {
                    videoEl.clone().appendTo($(this).find(imgBtmGradient));
                    const videoElSrc = $(this).find('video source');
                    videoElSrc.attr('src', videoData);
                }
            }
            if (YTData && isVisible && childEl < 1) {
                
                imgEl.clone().appendTo($(this).find(imgBtmGradient));
                const imgSrc = $(this).find('.video-slider__thumbnail');
                if (customImage) {
                    imgSrc.css('background-image', `url(${customImage})`);
                } if (!customImage && (YTData.indexOf("=") >= 0)) {
                    const YTDataVideoID = YTData.substring(YTData.lastIndexOf('=') + 1);
                    const YTThumbnail = `https://img.youtube.com/vi/${YTDataVideoID.replace(/&/g, "")}/mqdefault.jpg`;
                    imgSrc.css('background-image', `url(${YTThumbnail})`);
                } if (!customImage && (YTData.indexOf("=") < 0)) {
                    const YTDataVideoID = YTData.substring(YTData.lastIndexOf('/') + 1);
                    const YTThumbnail = `https://img.youtube.com/vi/${YTDataVideoID.replace(/&/g, "")}/mqdefault.jpg`;
                    imgSrc.css('background-image', `url(${YTThumbnail})`);
                }
            }
        });
    }
    loadVideos() {
        const video = $('.video-slider .product-gallery-swiper-video .product-gallery-swiper__slide');

        this.thumbnailSwiper.on('mouseenter', function () {
            const self = $(this);
            const thumbnail = self.find('.swiper-slide');
            $('.product-gallery-swiper__slide > div').removeClass().addClass('modal-content');
            $('.product-gallery-swiper__slide .modal-content *').remove();

            video.each(function (index) {
                const modal = $('.modal-content');
                const videoClass = 'modal-content--local-video';
                const ytClass = 'modal-content--yt-video';
                let ytVideoContainer = '';
    
                if ((thumbnail[index]) && (index === Number(thumbnail[index].getAttribute('slide-index')))) {
                    if (thumbnail[index].getAttribute('video-data')) {
                        const thumbVideoData = thumbnail[index].getAttribute('video-data');
                        const videoEl = $(`<video controls=""> <source src="${thumbVideoData}"></video>`);
                        $(this).find(modal).addClass(videoClass);
                        const videoContainer = $('.modal-content--local-video');
                        const videoElSrc = $(this).find('video source');
                        videoEl.appendTo(videoContainer).replaceWith(videoEl);
                        videoElSrc.attr('src', thumbVideoData);
                    }
                    if (thumbnail[index].getAttribute('yt-data')) {
                        const YTVideoData = thumbnail[index].getAttribute('yt-data');
                        const loadYTID = (spliceChar) => {
                            const YTDataVideoID = YTVideoData.substring(YTVideoData.lastIndexOf(spliceChar) + 1);
                            const youtubeEmbedLink = 'https://www.youtube.com/embed/';
                            const ytFormattedLink = youtubeEmbedLink + (YTDataVideoID? YTDataVideoID.replace(/&/g, ""): YTDataVideoID);
                            const ytEl = $(`<iframe class="custom-modal__body-iframe" src="${ytFormattedLink}" frameborder="0" allowfullscreen="" allow="autoplay; encrypted-media"></iframe>`);
                            $(this).find(modal).addClass(ytClass);
                            ytVideoContainer = $(this).find('.modal-content--yt-video');
                            const ytElSrc = $(this).find('iframe');
                            ytEl.appendTo(ytVideoContainer).replaceWith(ytEl);
                            ytElSrc.attr('src', ytFormattedLink).attr('yt-data', ytFormattedLink);
                        }
                        if (YTVideoData.indexOf("=") >= 0) {
                            loadYTID('=');
                        } else {
                            loadYTID('/');
                        }
                    }
                }
            });
        });
 
    }
    openModal() {
        const videoSlide = $('.product-gallery-swiper__slide');
        this.thumbnail.on('click', function () {
            const ytAutoplayLink = '?autoplay=1';
            const clickedIndex = $(this)[0].getAttribute('slide-index');
            const activeSlide = $(videoSlide[clickedIndex]);
            const activeSlideVideo = activeSlide.find('video');
            const activeSlideYT = activeSlide.find('iframe');
            const activeSlideYTSrc = activeSlide.find('iframe').attr('src');
            if (activeSlideVideo[0]) {
                const promise = activeSlideVideo[0].play();
                if (promise !== undefined) {
                    promise.then(_ => {
                        activeSlideVideo.trigger('play');
                    }).catch(error => {
                      console.log('Autoplay was prevented');
                      console.log(error);
                      activeSlideVideo.trigger('play');
                    });
                  }
            }
            if (activeSlideYT[0]) {
                const autoplayOnClick = activeSlideYTSrc + ytAutoplayLink;
                activeSlideYT.attr('src', autoplayOnClick);
            }
        });

        this.swiperBtns.on('click', function () {
            const ytAutoplayLink = '?autoplay=1';
            const activeSlideYT = $(this).parents('.modal-content').find('.swiper-slide-active iframe');
            const prevYTVideo = $(this).parents('.modal-content').find('.swiper-slide-prev iframe');
            const nextYTVideo = $(this).parents('.modal-content').find('.swiper-slide-next iframe');
            const activeSlideVideo = $(this).parents('.modal-content').find('.swiper-slide-active video');
            const prevSlideVideo = $(this).parents('.modal-content').find('.swiper-slide-prev video');
            const nextSlideVideo = $(this).parents('.modal-content').find('.swiper-slide-next video');
            if (activeSlideYT.length) {
            // Adding autoplay to active slides yt videos
                const activeYTVideo = $(this).parents('.modal-content').find('.swiper-slide-active iframe');
                const activeYTVideoSrc = activeYTVideo.attr('src');
                const autoplayOnClick = activeYTVideoSrc.replace(ytAutoplayLink,'') + ytAutoplayLink;
                activeYTVideo.attr('src', autoplayOnClick);
            }
            // Pausing autoplay for YT videos on previous slides
            if (prevYTVideo.length) {
                const prevYTVideoSrc = prevYTVideo.attr('src');
                const pausePrevOnClick = prevYTVideoSrc.replace(ytAutoplayLink,'');
                prevYTVideo.attr('src', pausePrevOnClick); 
            }
            // Pausing autoplay for YT videos on next slides
            if (nextYTVideo.length) { 
                const nextYTVideoSrc = nextYTVideo.attr('src');
                const pauseNextOnClick = nextYTVideoSrc.replace(ytAutoplayLink,'');
                nextYTVideo.attr('src', pauseNextOnClick); 
            }
            if(activeSlideVideo.length) {
                const promise = activeSlideVideo[0].play();
                if (promise !== undefined) {
                    promise.then(_ => {
                        activeSlideVideo.trigger('play');
                    }).catch(error => {
                      console.log('Autoplay was prevented');
                      console.log(error);
                      activeSlideVideo.trigger('play');
                    });
                  }
            }
            if(prevSlideVideo.length) {
                const promise = prevSlideVideo[0].pause();
                if (promise !== undefined) {
                    promise.then(_ => {
                        prevSlideVideo.trigger('pause');
                        prevSlideVideo[0].currentTime = 0;
                    }).catch(error => {
                        console.log('Pausing was prevented');
                        console.log(error);
                        prevSlideVideo.trigger('pause');
                    });
                }
            }
            if(nextSlideVideo.length) {
                const promise = nextSlideVideo[0].pause();
                if (promise !== undefined) {
                    promise.then(_ => {
                        nextSlideVideo.trigger('pause');
                        nextSlideVideo[0].currentTime = 0;
                    }).catch(error => {
                        console.log('Pausing was prevented');
                        console.log(error);
                        nextSlideVideo.trigger('pause');
                    });
                }
            }
        });
    }
    // Stopping videos when closing modal
    closeModal () {
        const ytAutoplayLink = '?autoplay=1';
        const modal = $('.custom-modal--product-gallery');
        modal.on('click', function() {
            const activeYTVideo = $(this).find('.swiper-slide-active iframe');
            const activeVideo = $(this).find('.swiper-slide-active video');
            if(activeVideo.length) {
                const promise = activeVideo[0].pause();
                if (promise !== undefined) {
                    promise.then(_ => {
                        activeVideo.trigger('pause');
                        activeVideo[0].currentTime = 0;
                    }).catch(error => {
                        console.log('Pausing was prevented');
                        console.log(error);
                        activeVideo.trigger('pause');
                    });
                }
            }
            if (activeYTVideo.length) {
                let activeYTVideoSrc = activeYTVideo.attr('src');
                const pauseActiveOnClick = activeYTVideoSrc.replace(ytAutoplayLink,'');
                activeYTVideo.attr('src', pauseActiveOnClick); 
            }
        }).children().on('click', function (e) {
            return false;
        });
    }
}

export default VideoThumbnails;