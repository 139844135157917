class EmbedModule {

    init() {
        this.processHtml();
    }

    processHtml() {
        // process URL
        this.processUrls();
        
        // add main-content--embed class to main
        this.addMainClass();
    }

    processUrls() {
        $('a').each(function () {
            const self = $(this);
            const attr = self.attr('href');
            if (attr !== undefined) {
                if (attr.indexOf('#') !== -1) {
                    return true;
                }
                
                if (attr.toLowerCase().indexOf('dopostbackwithoptions') !== -1) {
                    return true;
                }

                if (attr.indexOf('embed=1') !== -1) {
                    return true;
                }

                if (attr.indexOf('?') !== -1) {
                    const replacedAttr = self.attr('href') + "&embed=1";
                    self.attr('href', replacedAttr);
                }
                else
                {
                    const replacedAttr = self.attr('href') + "?embed=1";
                    self.attr('href', replacedAttr);
                }
            }
        });
    }

    addMainClass() {
        $('main').addClass('main-content--embed')
    }
}

export default EmbedModule;