const RethinkNav = function () {
    //prevent hamburger menu from posting
    $('.nav-rethink__hamburger-icon').on("click", function (e) {
        e.preventDefault();
    });

    //where applicable (item is not a direct link), prevent flyout menu link from doing its thing and load lvl2
    $('.rethink-menu__sidebar-list .js-rethink-menu__load-lvl2 span').on("click", function (e) {
        e.preventDefault();

        const container = $(".rethink-menu__sidebar-list-content");
        const url = $(this).attr('data-href');

        //ajax load page into container
        $.get(url, function (data) {
            data = $(data).find(".rethink-menu__sidebar-list-content");
            container.html(data.html());
        });

    });
};

export default RethinkNav;
