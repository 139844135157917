class BrowserDetection {
    static safariDetection() {
        const ua = navigator.userAgent;
        const rgxStr = new RegExp(/(Version\/)+(\d)+/g);
        const group = ua.match(rgxStr);
        let version;
        if (group && group.length > 0) {
            version = group[0].split('/')[1]
        }
        return {
            isSafari: group ? true : false,
            version: version ? version : null
        };
    };
}

export default BrowserDetection;