import General from '../general/general';
import Popup from '../popup/popup';

class CreateAnchor {
    init() {
        this.scrollToAnchor();
        this.openFlyout();
    };

    scrollToAnchor() {
        const general = new General();
        const popup = new Popup();

        /* create anchor elem */
        let anchorElem = $('.js-create-anchor');

        if (anchorElem) {
            anchorElem.each(function () {
                if ($(this).is('a')) {
                    $(this).click((e) => {
                        e.preventDefault();
                        general.smoothScroll($(this));
                    });
                } else {
                    let anchorButton = $(this).find('a');
                    anchorButton.each(function () {
                        $(this).click((e) => {
                            e.preventDefault();
                            let scrollElem = $(this).parents('.js-create-anchor');
                            general.smoothScroll(scrollElem);
                        });
                    });
                }
            });
        }

        /* existing ones */
        let scrollToElem = $('.js-lp-anchor');
        if (scrollToElem) {
            scrollToElem.click((e) => {
                let clickedPathName = e.target.pathname;
                let currentPathName = location.pathname;
                if (clickedPathName === currentPathName) {
                    e.preventDefault();
                    general.smoothScroll($(e.target));
                    /* on mobile phones, close the LP mobile menu */
                    popup.close();
                } else {
                    window.location.href = clickedPathName;
                }

            });
        }
    }

    openFlyout() {
        $('.js-open-flyout').on("click", function(e) {
            e.stopPropagation();
            $('.js-nav-contact').click();
         });
    }
}

export default CreateAnchor;
