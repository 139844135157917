class HtmlVideoPlayer {
    dynamicVideos = [];

    init() {
        this.showAccesibilityControls();
    }

    showAccesibilityControls() {
        const videos = document.body.querySelectorAll('video');
        document.addEventListener('keyup', (e) => {
            if (e.code === "Tab") {
                videos.forEach(v => {
                    if (!v.hasAttribute('controls')) {
                        v.setAttribute('controls', true);
                        this.dynamicVideos.push(v);
                    }
                })
            }
        });

        document.addEventListener("click", () => {
            if (this.dynamicVideos.length > 0) {
                this.dynamicVideos.forEach(v => v.removeAttribute('controls'))
            }
        })
    }
};

export default HtmlVideoPlayer;