var globalThis = require('globalthis')();

function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

var index = 1;
var chatUserId = uuidv4();
var currentCsrfId = '';
var prefix = '';
var cbBaseUrl = '';
var chatBoxTitle = 'Chatrine';
var cbCookieLevel = '';
var cbIsOpen = false;
var openBotAfterMsec;
var closeBotAfterMsec;
var urlMappingId;
var isBotUsed = false;

globalThis.sendMessage = function (message) {
    
    if (message != 'init' &&
        message != 'chat-evaluation'
		&& message.indexOf('select-option') == -1
        && message != 'more-questions' &&
        message != 'meta:open' &&
        message != 'meta:close' &&
        message != 'meta:use') {

		var plainMessage = message.replace('select-option-helpful:yes:', '');
		plainMessage = message.replace('select-option-helpful:no:', '');

		$('#chat-box')
				.append(
						'<div ng-repeat="message in messages" class="ng-scope"><small print-message="" message="message"><div class="bubble customer"><span ng-attr-class=""><span ng-show="message.priv"></span><span ng-bind-html="message.message" class="ng-binding">'
								+ plainMessage
								+ '</span></span></div><br></small>');
	}

	var data1 = {};
	data1["username"] = chatUserId;
	data1["message"] = prefix + message;
	data1["lang"] = window.location.href;
	data1["urlMappingId"] = urlMappingId;

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	var clientId = urlParams.get('clientId');
	var stagingLevelId = urlParams.get('stagingLevelId');
	var audienceId = urlParams.get('audienceId');

	data1["clientId"] = clientId;
	data1["stagingLevelId"] = stagingLevelId;
	data1["audienceId"] = audienceId;
	data1["cookieLevel"] = cbCookieLevel;

	$('.option').prop("onclick", null);

		$.ajax({
			url : cbBaseUrl,
			type : "POST",
			data : JSON.stringify(data1),
			contentType : "application/json; charset=utf-8",
			dataType : "json",
			success : function(data) {
	
				if (data.message.indexOf('chat-meta') == -1) {
					// console.log("answer-message: " + data.message);
					// console.log("answer-actual-information: "
					// + data.actualInformation);
	
					// if (!"no-answer" == data.message) {
					prefix = '';
	
					if (data.message.indexOf('var-flow-node:') != -1) {
						prefix = data.message.substring(0, 21);
						// var-flow-node:000000:
						data.message = data.message.substring(21,
								data.message.length);
					}
	
					// Luka - retrun message contains HTML (any more
					// questions buttons) - need to intercept it and set
					// button
					// type="button" to prevent submitting
					data.message = data.message.replaceAll('<button ',
							'<button type="button" ');
	
					$('#chat-box')
							.append(
									'<small print-message="" message="message" class="message"><div class="bubble"><span ng-attr-class=""><span ng-show="message.priv"></span><span ng-bind-html="message.message" class="ng-binding">'
											+ data.message
											+ '</span></span></div><br></small>');
					scrollMessages();
	
                    if (data.actualInformation && message != 'init'
                        && message != 'chat-evaluation'
                        && !message.includes('select-option:')
                        && message != 'more-questions'
                        && message != 'meta:open'
                        && message != 'meta:close'
                        && message != 'meta:use'
                        && prefix === '') {
						sendMessage('more-questions');
						scrollMessages();
					}
	
				}
			}
		});

	// console.log("**** chatrene sendMessage end");

	function scrollMessages() {
		// $(".chat-box").animate({ scrollTop:
		// $('.chat-box').prop("scrollHeight")},
		// 1000);
		setTimeout(function() {
			var objDiv = document.getElementById("chat-box");
			objDiv.scrollTop = objDiv.scrollHeight;
		}, 100);
	}
}

const findChatrene = function() {
	if (document.getElementById("chatBoxContainer")) {
		//console.log("*** Chaterene has been found - initializing")

        if (cbBaseUrl === '') {
            //console.log("*** cbBaseUrl", cbBaseUrl);

            cbCookieLevel = readCookie('CMSCookieLevel');

            //console.log("*** openBotAfterMsec", openBotAfterMsec);
            //console.log("*** closeBotAfterMsec", closeBotAfterMsec);

            $
					.ajax({
						url : 'https://chatbot.konicaminolta.eu/config/resolveurl?baseUrl=' + window.location.href,
						type : "GET",
						contentType : "application/json; charset=utf-8",
						dataType : "json",
						success : function(data) {
							cbBaseUrl = data.url;
							chatBoxTitle = data.title;
							openBotAfterMsec = data.openBotAfterMsec;
                            closeBotAfterMsec = data.closeBotAfterMsec;
							urlMappingId = data.urlMappingId;

							$('#chatBoxContainer').html(getChatBoxTemplate());

							// handlers here
							$('#newMessageInput').on('keypress', function(e) {

								if (e.which == 13) {

									var message = $('#newMessageInput').val();
									if (message !== '') {
										sendMessage(message);
										$('#newMessageInput').val('');
									}

									// prevent submitting the form
									e.preventDefault();
									return false;
								}
							});

							$('#chatBoxSendText').on('click', function(e) {
								e.preventDefault();
								var message = $('#newMessageInput').val();

								if (message !== '') {
									sendMessage(message);
									$('#newMessageInput').val('');
								}

								// now button should not trigger the outer form
								return false;
							});

							$('.panel-heading').click(function() {
								
								if (!cbIsOpen) {
									openBot();
								} else {
									closeBot();
								}
							});

                            $('#newMessageInput').keyup(function () {
                            //$('#newMessageInput').on('change', function () {
                                console.log("*** newMessageInput");
                                if (!isBotUsed) {
                                    console.log("*** newMessageInput - Bot is NOT used");
									isBotUsed = true;
									sendMessage("meta:use");
								}
							});

							// finally, call init
							sendMessage('init');

                            console.log("*** openBotAfterMsec", openBotAfterMsec);
                            console.log("*** closeBotAfterMsec", closeBotAfterMsec);


							setTimeout(function() {
								openBot();
							}, openBotAfterMsec);
						}
					});

		}

		function readCookie(name) {

			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ')
					c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) == 0)
					return c.substring(nameEQ.length, c.length);
			}
			return null;
		}

		// draw the thing
		function getChatBoxTemplate() {
			var template = '';
			template += '<div id="chatBox" class="panel panel-default" style="bottom: 30px; height: auto;">';
			template += '<div class="panel-heading">';
			template += '<div class="container" >';
			template += '        <div class="row">';
			template += '<div class="panel-title col-xs-12"><strong>'
					+ chatBoxTitle + '</strong></div>';
			template += '        </div>';
			template += '</div>';
			template += '</div>';
			template += '<div class="panel-body" style="display: none;">';
			template += '<div class="container" >';
			template += '        <div class="row">';
			template += '           <div id="chat-box" class="chat-box">';
			template += '            <div class="row" id="textInputArea">';
			template += '<div class="form-group">';
			template += '<!--<span><small>You will send this message to <strong>{{sendTo}}</strong></small></span>-->';
			template += '<input id="newMessageInput" type="text" class="form-control" placeholder="Write your message and hit enter..." ng-model="newMessage" ng-keyup="$event.keyCode == 13 ? sendMessage() : startTyping()"/>';
			template += '<button type="button" id="chatBoxSendText"><div></div></button>';
			template += '</div>';
			template += '</div>';
			template += '</div>';
			template += '</div>';
			template += '</div>';
			template += '</div>';
			template += '</div>';
			return template;
		}
		
		function openBot() {
            //console.log("*** openBot()");
			if (!cbIsOpen) {
				// open
				console.log("open bot");
				$('#openChatBox').hide();
				$('#closeChatBox').show();
				$('.panel-body').css("display","block");
				$('.panel-heading').addClass("open");
				cbIsOpen = true;
				sendMessage("meta:open");
				$('#newMessageInput').focus();
				
				setTimeout(function() {
					closeBotConditional();
				}, closeBotAfterMsec);
			}
		}

		function closeBot() {
			// close
			console.log("close bot");
			$('#closeChatBox').hide();
			$('#openChatBox').show();
			$('#chatBox').css("height", "auto");
			$('.panel-body').css("display","none");
			$('.panel-heading').removeClass("open");
			cbIsOpen = false;
			sendMessage("meta:close");
		}

		function closeBotConditional() {
			
			if (!isBotUsed) {
				closeBot();
			}
		}

		// console.log("*** Chaterene initialization done")
	} else {
		// console.log("Chatrene was NOT found")
	}
}

export default findChatrene;
