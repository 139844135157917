class DownloadTracker 
{
    init(){
        this.addTracking();
    }

    addTracking(){
        $('a').each(function () {
            const self = $(this);
            const attr = self.attr('href');
            if (attr !== undefined) {
                if (attr.toLowerCase().indexOf('/getmedia/') !== -1) {
                    self.attr('data-track-download', attr);
                    self.on('click', function() {
                        const elem = $(this);
                        $.post(`/cmspages/dd/trackdownload.ashx?url=${elem.attr('data-track-download')}`, function(e) {
                            console.log(e);
                        });
                    });
                }
            }
        });
    }
}

export default DownloadTracker;