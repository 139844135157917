export default class ProductQuestionaire {
    
    //original code taken from lp-quickcheck


    constructor() {
        this.cookieName = "ProductQuestionaireAnswers";
        this.answers = [];
        this.questionaireID =  $("#hidden_questionaireID").val();
    }

    //'ctor'
    init() {
        //reset cookie
        document.cookie = `${this.cookieName}=`;

        //bind answers onclick
        this.bindAnswerButtonsClickEvent();

        //bind back button onclick
        this.bindBackButtonClickEvent();

        //show first box
        this.showActiveBox(0);
    }

    showActiveBox(index) {
        $($(".product-questionaire__box")[index]).addClass("product-questionaire__box--active").siblings().removeClass("product-questionaire__box--active");
    }


    //save current answer selection into a cookie
    updateCookie() {
        let cookieText = `${this.cookieName}=${this.questionaireID}|${this.answers.join("|")}`;
        document.cookie = cookieText;
    }
    
    //refresh whatever needs refreshing
    refresh() {
        //click the invisible button to refresh updatePanel
        $("#hidden_btnShowProductOverview").click();
    }

    bindBackButtonClickEvent() {
        const that = this;

        $(".js-product-questionaire-previous").on("click", function() {
        
            const currentBoxIndex = $(this).parents(".product-questionaire__box--active").index();
            if (currentBoxIndex > 0) {
                //move back
                that.showActiveBox(currentBoxIndex - 1);
                
                //remove last answer
                that.answers.pop();

                that.updateCookie();

                that.refresh();
            }
        });
    }

    bindAnswerButtonsClickEvent() {
        const that = this;
        const numberOfQuestions = $(".product-questionaire__box").length;

        $(".js-next-product-questionaire-box").on("click", function() {

            const isLastQuestion = $(this).parents(".product-questionaire__box").index() === numberOfQuestions - 1;
            const nextIndex =$(this).parents(".product-questionaire__box").index() + 1;

            // save value of the selected answer
            const answerId = parseInt($(this).data("answer-id")); //!!! answer ID
            that.answers.push(answerId); //push answer id to answers array

            //save current selection into cookie
            that.updateCookie();

            //refresh updatePanel with HWOverview
            that.refresh();

            // if last... do i even need this?
            if (!isLastQuestion) {
                //show next box
                that.showActiveBox(nextIndex);

            }

            if(isLastQuestion){
                $(this).parents('.product-questionaire__box').children('.js-next-product-questionaire-box').each(function(){
                    $(this).addClass('d-none');
                });
            }
            
        });
    }

}
