
class FooterRethinkDropdown {

    init() {
        this.openFooterDropdown();
    }

    openFooterDropdown() {
        const $dropdownLink = $('.footer-rethink__top-lang-link');
        const $dropdown = $('.footer-rethink__top-lang-dropdown');
        const $footer = $('.footer-rethink');
        const $dropdownArrow = $('.footer-rethink__top-lang-link-arrow');
        const $dropdownArrowOpen = 'footer-rethink__top-lang-link-arrow--open';
        const dropdownOpenClass = 'footer-rethink__top-lang-dropdown--open';

        $dropdownLink.on('click', function (event) {
            event.stopPropagation();
            $dropdown.toggleClass(dropdownOpenClass);
            $footer.find($dropdownArrow).toggleClass($dropdownArrowOpen);
        })

        $(document).click(function () {
            $dropdown.removeClass(dropdownOpenClass);
            $dropdownArrow.removeClass($dropdownArrowOpen);
        })
    }
}

export default FooterRethinkDropdown;
