class IsInViewport {
    init(img, resize = false) {
        const inViewport = this.inViewport;
        inViewport(img, resize);
        setTimeout(function () {
            $(window).on('resize scroll', function () {
                inViewport(img, resize);
            });
        }, 100);
    }

    inViewport(img, resize) {
        if (img) {
            img.each(function () {
                const self = $(this);
                const elementTop = $(this).offset().top;
                const elementBottom = elementTop + $(this).outerHeight();
                const viewportTop = $(window).scrollTop();
                const viewportBottom = viewportTop + $(window).height();
                const isMobile = window.matchMedia('(max-width: 767px)').matches;

                if (resize) {
                    const dataSrc = isMobile ? (self.attr('mobile-img-src') || self.attr('img-src')) : self.attr('img-src');
                    self.css('background-image', `url('${dataSrc}')`);
                }

                if (self.attr('data-loaded')) {
                    return;
                }

                if (elementBottom > viewportTop - ($(window).height() / 3) && elementTop < viewportBottom + ($(window).height() / 3)) {
                    if (self.prop("tagName") === 'IMG' && self.attr('img-src')) {
                        self.attr('src', self.attr('img-src'));
                    }
                    if (self.prop("tagName") !== 'IMG' && self.attr('img-src')) {
                        const dataSrc = isMobile ? (self.attr('mobile-img-src') || self.attr('img-src')) : self.attr('img-src');
                        self.css('background-image', `url('${dataSrc}')`);
                    }

                    self.attr('data-visible', 'visible');
                    self.attr('data-loaded', 'loaded');
                }
            });
        }
    }
}

export default IsInViewport;