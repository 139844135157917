import breakpoints from '../../pages/styles/custom/_grid.scss';
import General from '../general/general';

const widget4ImageSubmenu = () => {
    const windowWidth = window.innerWidth;
    const general = new General();

    if (windowWidth < Number(breakpoints.lgMin)) {
        const swiperEl = $('.widget-4-image-submenu-swiperEl');
        general.initSwiper(swiperEl);
    }
};

export default widget4ImageSubmenu;