const SuccessStoryFilter = function () {
    const allFilterCategories = document.querySelectorAll('.success-stories-overview__category');
    const allCatElem = document.querySelectorAll(".success-stories-overview__filter [type=checkbox]");
    const filterWrapper = document.querySelector(".success-stories-overview__wrapper");
    const allCardsElem = document.querySelectorAll(".success-stories-overview__items-wrapper .overview-item");
    const viewModeKentico = document.getElementById('success-story-mode');

    const selectedCats = [];
    let shownElements = 0;

    allCatElem.forEach((catElem) => {
        catElem.addEventListener("change", (event) => {
            // get selected cat
            const { target } = event;
            const { value } = target;

            if (target.checked) {
                selectedCats.push(value);
            } else {
                const targetIndex = selectedCats.indexOf(value);
                selectedCats.splice(targetIndex, 1);
            }

            if (selectedCats.length > 0) {
                filterCards(selectedCats);
            } else {
                allCardsElem.forEach(card => card.classList.remove('hidden'));
                shownElements = 0;
            }
        });
    });

    // filter
    function filterCards(selectedCats) {
        [...allCardsElem].forEach((c) => {
            const { category } = c.dataset;

            const allCardCategories = category.trim().split(" ");
            const arrEqualInSize = allCardCategories.length == selectedCats.length;

            let shouldHide = false;

            const allSorted = allCardCategories.sort();
            const selectedSorted = selectedCats.sort();

            shouldHide = selectedCats.every(cat => allCardCategories.includes(cat));

            // if (arrEqualInSize && selectedCats.length > 1) {
            //     shouldHide = allSorted.reduce((container, current, index) => {
            //         return container && current == selectedSorted[index];
            //     }, true);
            // } else if (selectedSorted.length == 1) {
            //     shouldHide = allSorted.some((cat) => selectedSorted.includes(cat));
            // }

            if (!shouldHide) {
                c.classList.add("hidden");
                shownElements -= 1;
            } else {
                c.classList.remove("hidden");
                shownElements += 1;
            }

            if (Math.abs(shownElements) === allCardsElem.length) {
                // console.log('there are no elements')
            }
        });
    }

    // hide the filters that don't have entries
    const cardCategories = [];
    [...allCardsElem].forEach(c => {
        const { category } = c.dataset;
        if (category && category !== ' ') {
            const allCardCategories = category.trim().split(" ");
            allCardCategories.forEach(cat => {
                if (!cardCategories.includes(cat)) {
                    cardCategories.push(cat);
                }
            })
        }
    });


    if (viewModeKentico && viewModeKentico.value === 'LiveSite') {
        // hide one category entry
        if (cardCategories.length > 0) {
            const hideFilters = [...allCatElem].filter((cat) => {
                return !cardCategories.includes(cat.value);
            });
            hideFilters.forEach(f => f.parentElement.classList.add('hidden'));
        }

        // hide complete category if all filters are hidden
        allFilterCategories.forEach(filterCat => {
            const filters = filterCat.querySelectorAll('.checkbox');
            const hiddenFilters = [...filters].filter(f => f.classList.contains('hidden'))
            if (filters.length === hiddenFilters.length) {
                filterCat.classList.add('hidden')
            } else {
                filterCat.classList.remove('hidden')
            }
        })
    }

    if (allFilterCategories.length === 0) {
        // hide the wrapper for Filter UI
        // regular view will be displayed
        if (filterWrapper) {
            filterWrapper.classList.add('hidden');
        }
    }
};

export default SuccessStoryFilter;