import General from '../general/general';

export const widgetSliderWithImages = () => {
    const swiperEl = $('.widget-slider-with-images-column.-right');
    const parentEl = swiperEl.closest('.widget-slider-with-images');
    const general = new General();

    const swiperOptions = (parentEl) =>
    ({
        effect: 'fade',
        loop: false,
        slidesPerView: 1,
        navigation: {
            nextEl: parentEl.find('.widget-slider-with-images-swiperNav .swiper-button-next'),
            prevEl: parentEl.find('.widget-slider-with-images-swiperNav .swiper-button-prev')
        },
        on: {
            init() {
                handleSwiperCounter(this);
            },
            slideChangeTransitionStart() {
                handleSwiperCounter(this);

                /* fix fade */
                const slides = this.slides;
                const slidesArr = Array.from(slides);
                slidesArr.forEach(slide => {
                    if(!slide.classList.contains('swiper-slide-active')) {
                        slide.classList.add('opacity-0')
                    } else {
                        slide.classList.remove('opacity-0')
                    }
                });
            }
        }
    });

    function handleSwiperCounter(swiper) {
        const parent = swiper.$el.closest('.widget-slider-with-images');
        const bigCounter = swiper.$el.find('.js-swiper-big-counter');
        const smallCounter = parent.find('.js-swiper-counter');

        /* fill small Counter */
        smallCounter.html(`${swiper.realIndex + 1} / ${swiper.slides.length}`);
        bigCounter.html(`0${swiper.realIndex + 1}`);
    }

    general.initSwiper(swiperEl, swiperOptions(parentEl));
}