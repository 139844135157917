class OverlayModule {
    init () {
        this.toggleOverlayModule();
    }

    toggleOverlayModule() {
        const overlayModule = $('.overlay-module');
        const contentCloseButton = $('.overlay-module-content__close');
        const hideButton = $('.overlay-module__hide');
        const openButton = $('.overlay-module__open');
        const openOverlayModuleClass = 'overlay-module--is-open';
        contentCloseButton.on('click', () => { 
            overlayModule.parent().removeClass(openOverlayModuleClass);
        });
        openButton.on('click', () => { 
            overlayModule.parent().addClass(openOverlayModuleClass);
        });
        hideButton.on('click', (e) => {
            e.stopPropagation();
            overlayModule.parent().addClass('overlay-module--hidden');
        });
    }
}
export default OverlayModule;