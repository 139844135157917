import SimpleLightbox from 'simple-lightbox'
import 'simple-lightbox/dist/simpleLightbox.min.css'

class Lightbox {

    constructor() {
        SimpleLightbox.defaults.loadingCaption = '';
    }

    lightboxInit(el) {
        new SimpleLightbox({elements: el});
    }
}

export default Lightbox;
