class ProductList{
    init(){
        this.hideEmptyHeaders();
    }

    hideEmptyHeaders(){
        // do nothing if not on the product list template
        if($('.product-link-list')){
            $('.list-item > .nested.second-level').each(function() { 
                var currentElem = $(this);
                if(currentElem.children('ul').children().length === 0) {
                    currentElem.closest('.list-item').addClass('hidden');
                }
            });
        }
    }
}

export default ProductList;