import getEventListeners from 'geteventlisteners';
import { Callbacks } from 'jquery';
const debounce = require('debounce');
class Subnavigation {
    subNavEl = document.querySelectorAll(".subnavigation");
    headerEl = document.querySelector(".intro-header");
    viewModeEl = document.querySelector("[itemId=viewMode]");
    mainNavEl = document.querySelector('nav.nav-rethink');
    mainNavHeight = this.mainNavEl ? this.mainNavEl.clientHeight : 0;

    init(resizeEvent) {
        window.subnavigation = {};
        const windowWidth = window.innerWidth;
        let subNavZIndex = 20;

        this.subNavEl.forEach((sn, snIndex) => {
            if (this.headerEl && this.viewModeEl && this.viewModeEl.value === "LiveSite") {
                this.headerEl.append(sn);
            }

            sn.style.zIndex = subNavZIndex;
            subNavZIndex--;

            const snInitialPosition = sn.getBoundingClientRect();
            const windowInitialScroll = window.scrollY;
            const snInitialTop = snInitialPosition.top;
            const overflowEl = sn.querySelector('.subnavigation__overflow');
            if (overflowEl) {
                overflowEl.remove();
            }
            const subNavItems = sn.querySelectorAll('li');
            subNavItems.forEach(si => si.classList.remove('hidden', 'overflow')); // plain start

            sn.setAttribute('data-top', snInitialTop + windowInitialScroll);
            const showMoreLabelEl = sn.querySelector(".js-show-more");
            if (showMoreLabelEl.classList.contains('hidden')) {
                showMoreLabelEl.classList.remove('hidden');
            }
            const subnavWidth = sn.querySelector(".subnavigation__list").clientWidth;
            const toggleMobileNavigationEl = sn.querySelector('.js-toggle-mobile-subnavigation');

            const listItems = sn.querySelectorAll("li:not(.overflow)");
            if (resizeEvent) {
                const clickEvents = toggleMobileNavigationEl.getEventListeners('click') || showMoreLabelEl.getEventListeners('click');
                clickEvents && clickEvents.forEach(e => {
                    toggleMobileNavigationEl.removeEventListener('click', this.toggleMobileNavigation);
                    showMoreLabelEl.removeEventListener('click', this.toggleDesktopNavigation);
                });
            }

           
            this.calculateSubnavigationItems({ sn, windowWidth, subnavWidth, listItems, showMoreLabelEl, toggleMobileNavigationEl }, (snHeight) => {
                if (parseInt(snInitialTop + windowInitialScroll, 10) <= window.scrollY + this.mainNavHeight) {
                    sn.classList.add('fixed');
                    sn.style.top = `${this.mainNavHeight + snHeight * snIndex}px`;
                    sn.style.transform = 'translateY(0px)'
                } else {
                    sn.style.transform = `translateY(-${snHeight}px)`
                }
            });

            document.addEventListener('scroll', debounce((e) => {
                const isMobileActive = sn.querySelector('.subnavigation__list.open');
                const subNavPosition = sn.getAttribute('data-top');
                const subNavOpenPosition = sn.getAttribute('data-open-top');
                const calculateTopPositionFrom = subNavOpenPosition ? subNavOpenPosition : subNavPosition;
                let subnavHeight = sn.clientHeight;

                if (isMobileActive) {
                    let mobileSubnavFirstLiEl = sn.querySelector('li');
                    let mobileSubnavHeight = mobileSubnavFirstLiEl.clientHeight;
                    if (parseInt(calculateTopPositionFrom, 10) <= window.scrollY + this.mainNavHeight) {
                        sn.classList.add('fixed');
                        sn.style.top = `${this.mainNavHeight + subnavHeight * snIndex}px`;
                        sn.style.transform = 'translateY(0px)'
                    } else {
                        console.log('aaaa', calculateTopPositionFrom)
                        sn.classList.remove('fixed');
                        sn.style.top = 'initial';
                        sn.style.transform = `translateY(-${mobileSubnavHeight}px)`
                    }
                } else {
                    if (parseInt(subNavPosition, 10) <= window.scrollY + this.mainNavHeight) {
                        sn.classList.add('fixed');
                        sn.style.top = `${this.mainNavHeight + subnavHeight * snIndex}px`;
                        sn.style.transform = 'translateY(0px)'
                    } else {
                        sn.classList.remove('fixed');
                        sn.style.top = 'initial';
                        sn.style.transform = `translateY(-${subnavHeight}px)`
                    }
                }               
            }, 100));

        });

        this.closeDesktopNavigationOnBodyClick();
    }

    toggleMobileNavigation(e) {
        const { currentTarget } = e;
        const listEl = currentTarget.nextElementSibling;
        listEl.classList.toggle('open');
        let timer = 0;
        timer = setTimeout(() => 
        {
            const subNavEl = currentTarget.closest('.subnavigation');

            if (listEl.classList.contains('open')) {
                const currentPosition = currentTarget.getBoundingClientRect().top;
                subNavEl.setAttribute('data-open-top', currentPosition + window.scrollY);
            } else {
                subNavEl.setAttribute('data-open-top', '');
            }
        }, 251); // wait for subnav to open        
    }

    toggleDesktopNavigation(e) {
        const { currentTarget } = e;
        const listEl = currentTarget.querySelector('ul');
        listEl.classList.toggle('open');
    }

    calculateSubnavigationItems({
        sn,
        windowWidth,
        subnavWidth,
        listItems,
        showMoreLabelEl,
        toggleMobileNavigationEl
    }, callback) {
        if (!listItems) {
            return;
        }

        let totalWidth = 0;
        let lastIteratedItem = 0;
        let hasOverflow = false;
        let overflowItems = [];

        if (windowWidth >= 768) {
            for (let i = 0; i < listItems.length; i++) {
                if (
                    totalWidth + listItems[i].clientWidth + (showMoreLabelEl.clientWidth + 56) < subnavWidth) {
                    totalWidth += listItems[i].clientWidth;
                } else {
                    hasOverflow = true;
                    break;
                }
                lastIteratedItem = i;

            }
            listItems.forEach((li, index) => {
                if (index >= lastIteratedItem && !li.classList.contains("js-show-more")) {
                    li.classList.add('overflow');
                    li.classList.add("hidden");
                    overflowItems.push(li);
                }
            });

            if (hasOverflow) {
                const overflowWrapper = document.createElement('ul');
                overflowWrapper.classList.add('subnavigation__overflow');
                overflowItems.forEach(ot => overflowWrapper.innerHTML += ot.outerHTML);
                // overflowWrapper.append(overflowItems);
                const alreadyHasOverflowEl = showMoreLabelEl.querySelector('.subnavigation__overflow');
                if (!alreadyHasOverflowEl) {
                    showMoreLabelEl.append(overflowWrapper);
                }
                if (showMoreLabelEl.classList.contains('hidden')) {
                    showMoreLabelEl.classList.remove('hidden');
                }
                const listItems = showMoreLabelEl.querySelectorAll('li');
                listItems.forEach(li => li.classList.remove('hidden'));

                showMoreLabelEl.addEventListener("click", this.toggleDesktopNavigation);

            } else {
                showMoreLabelEl.classList.add('hidden');
            }
        } else {
            showMoreLabelEl.classList.add('hidden');
            listItems.forEach(li => {
                if (!li.classList.contains('js-show-more')) {
                    li.classList.remove('hidden');
                }
            });
            toggleMobileNavigationEl.addEventListener('click', this.toggleMobileNavigation)
        }

        callback(sn.clientHeight);
    }

    closeDesktopNavigationOnBodyClick() {
        const { body } = document;
        body.addEventListener('click', function (e) {
            const { target } = e;
            const isNavigation = target.closest('.js-show-more');
            const overflowEl = document.querySelectorAll('.subnavigation__overflow.open');
            if (!isNavigation) {
                overflowEl.forEach(oe => oe.classList.remove('open'));
            }
        })
    }
};

export default Subnavigation;