// Internet Explorer fix for startsWith error
if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (prefix) {
        return this.slice(0, prefix.length) == prefix;
    };
}
const FontFaceObserver = require('fontfaceobserver');
const debounce = require('debounce');
import Header from './header/header';
import CustomForm from './custom-form/custom-form';
import ImageSwiper from './image-swiper/image-swiper';
import ProductInfoSwiper from './product-info-swiper/product-info-swiper';
import ProductSpecifications from './product-specifications/product-specifications';
import MultipleInstances from './general/multipleInstances';
import ProductInfo from './product-info/product-info';
import NewsList from './news-list/news-list';
import EventAgenda from './event-agenda/event-agenda';
import HomeIntro from "./home-intro/home-intro";
import EventLocation from "./event-location/event-location";
import HomeIntroAnimations from './home-intro/home-intro-animations';
import CompareLane from './compare-lane/compare-lane';
import ProductInfoPreviewSwiper from './product-info/product-info-preview-swiper';
import ProductGallerySwiper from './product-info/product-info-gallery-swiper';
import ProductFilter from './product-filter/product-filter';
import KenticoForm from './kentico-form/kentico-form';
import CreateAnchor from './create-anchor/create-anchor';
import LinkCleaner from './link-cleaner/link-cleaner';
import HomeIntroMediaSwap from './home-intro/home-intro-media-swap';
import EmailEncrypt from './email-encrypt/email-encrypt';
import Popup from './popup/popup';
import Widget4StepPopup from './widget-4-step/widget-4-step-popup';
import LpQuickCheck from './lp-quickcheck/lp-quickcheck';
import LpShoveler from './lp-shoveler/lp-shoveler';
import ProductInfoTabs from './product-info/product-info-tabs';
import titleHeaderMobile from './title/title-header-mobile';
import KenticoFormValidationErrors from './kentico-form/kentico-form-validation-errors';
import VideoSwiper from './video-slider/video-slider';
import VideoThumbnails from './video-slider/video-slider-thumbnail';
import VideoDescriptionLimit from './video-slider/video-slider-description-limit';
import EmbedModule from './embed-module/embed-module';
import LazyLoad from './lazy-loading/lazy-loading';
import General from './general/general';
import LanguagePopup from './language-pop-up/languagePopup';
import OverlayModule from './overlay-module/overlay-module';
import ResizeText from './calculator/jquery.resizeText';
import Calculator from './calculator/calculator';
import ClimateSlider from './sustainability-slider/climate-slider';
import lpHeader from './lp-header/lp-header';
import ProductList from './product-list/productList';
import DownloadTracker from './download-tracking/downloadTracker';
import ProductQuestionaire from './product-questionaire/product-questionaire';
import FooterRethinkDropdown from './footer-rethink/footer-rethink';
import swiperHeader from './swiper-header/swiper-header';
import RethinkMenu from './rethink-menu/rethink-menu';
import RethinkNav from './rethink-nav/rethink-nav';
import dokoniFindCalculator from './dokonifind-calculator/calculator';
import userCentrics from './usercentrics/usercentrics';
import CustomDropdown from "./custom-dropdowns/custom-drowdown";
import HomeSelect from './home-select/home-select';
import accordion from './accordion/accordion';
import ProductFunctionalities from './product-functionalities/product-functionalities';
import newsDetailFixImgMargin from './news/news-detail-fix-img-margin';
import findChatrene from './chatrene/chatrene';
import swiperInstance from './rethink-work/swiper';
import OverviewSubsection from './overview-subsection/overview-subsection';
import HtmlVideoPlayer from './video-player/html-video-player';
import handleResponsiveBgImagesForSmallScreens from './bg-image/handleResponsiveBgImagesForSmallScreens.js';
import SuccessStoryFilter from './success-story/success-stories-filter';
import MarketoFileUpload from './MarketoFileUpload/MarketoFileUpload';
import lpShadow from './lp-shadow/lp-shadow';
import hubspot from './hubspot-form/hubspot-form';
import dynamicsForm from './dynamics-form/dynamics-form';
import Subnavigation from './subnavigation/subnavigation';
import webpImages from './webp-images/webp-images';
import widget4ImageSubmenu from './widget-4-image-submenu/widget-4-image-submenu';

import { widgetSliderWithImages } from './widget-slider-with-images/widget-slider-with-images';

const modules = () => {
    let header = new Header();
    let customForm = new CustomForm();
    let newsList = new NewsList();
    const imageSwiper = new ImageSwiper();
    const productInfoSwiper = new ProductInfoSwiper();
    const multipleInstances = new MultipleInstances();
    const productSpecifications = new ProductSpecifications();
    const productInfo = new ProductInfo();
    const eventAgenda = new EventAgenda();
    const homeIntro = new HomeIntro();
    const eventLocation = new EventLocation();
    const homeIntroAnimations = new HomeIntroAnimations();
    const compareLane = new CompareLane();
    const productInfoPreviewSwiper = new ProductInfoPreviewSwiper();
    const productGallerySwiper = new ProductGallerySwiper();
    const productFilter = new ProductFilter();
    const homeIntroMediaSwap = new HomeIntroMediaSwap();
    let createAnchor = new CreateAnchor();
    const embedModule = new EmbedModule();
    let linkCleaner = new LinkCleaner();
    const emailEncrypt = new EmailEncrypt();
    const popup = new Popup();
    const widget4StepPopup = new Widget4StepPopup();
    // lpQuickCheck initiated with first box
    const lpQuickCheck = new LpQuickCheck();
    const lpShoveler = new LpShoveler();
    const productInfoTabs = new ProductInfoTabs();
    const titleheadermobile = new titleHeaderMobile();
    const videoSwiper = new VideoSwiper();
    const videoThumbnails = new VideoThumbnails();
    const videoDescriptionLimit = new VideoDescriptionLimit();
    let lazyLoad = new LazyLoad();
    const languagePopup = new LanguagePopup();
    const overlayModule = new OverlayModule();
    const calculator = new Calculator();
    const resize = new ResizeText();
    const climateSlider = new ClimateSlider();
    const productList = new ProductList();
    const downloadTracker = new DownloadTracker();
    //KON-2302 - productQuestionaire
    const productQuestionaire = new ProductQuestionaire();
    const dokoniFind = new dokoniFindCalculator();
    const uc = new userCentrics();
    let customDropdown = new CustomDropdown();
    const footerRethinkDropdown = new FooterRethinkDropdown();
    let homeSelect = new HomeSelect();
    const productFunctionalities = new ProductFunctionalities();
    const overviewSubsection = new OverviewSubsection();
    const htmlVideoPlayer = new HtmlVideoPlayer();
    const marketoFileUpload = new MarketoFileUpload();
    const subnavigation = new Subnavigation();

    $(() => {
        var fontData = {
            'OpenSans-Regular': { weight: 400 },
            'OpenSans-Light': { weight: 300 },
            'OpenSans-SemiBold': { weight: 600 },
            'OpenSans-Bold': { weight: 700 },
            'OpenSans-SemiBold': { weight: 600 },
            'OpenSans-ExtraBold': { weight: 800 },
            'OpenSansCondensed-Bold': { weight: 700 }
        };

        var observers = [];
        Object.keys(fontData).forEach(function (family) {
            var data = fontData[family];
            var obs = new FontFaceObserver(family, data);
            observers.push(obs.load());
        });

        Promise.all(observers)
            .then(function (fonts) {
                $('html').addClass('font-loaded');
            })
            .catch(function (err) {
                console.warn('Some critical font are not available:', err);
            });

        header.init();
        lpHeader();
        customForm.init();
        imageSwiper.init();
        productInfoSwiper.init();
        productInfo.init();
        productSpecifications.init();
        multipleInstances.init();
        eventAgenda.init();
        eventLocation.init();
        newsList.init();
        homeIntro.init();
        homeIntroAnimations.init();
        compareLane.init();
        productInfoPreviewSwiper.init();
        productGallerySwiper.init();
        productFilter.init();
        linkCleaner.init();
        homeIntroMediaSwap.init();
        emailEncrypt.init();
        widget4StepPopup.init();
        popup.init();
        lpQuickCheck.init();
        lpShoveler.init();
        productInfoTabs.init();
        titleheadermobile.init();
        videoSwiper.init();
        videoThumbnails.init();
        videoDescriptionLimit.init();
        lazyLoad.init();
        languagePopup.init();
        overlayModule.init();
        productList.init();
        downloadTracker.init();
        //KON-2302
        productQuestionaire.init();
        footerRethinkDropdown.init();
        RethinkMenu();
        RethinkNav();
        swiperHeader();
        dokoniFind.init();
        uc.init();
        customDropdown.init();
        homeSelect.init();
        productFunctionalities.init();
        newsDetailFixImgMargin();
        //KON-4027
        findChatrene();
        swiperInstance();
        accordion();
        $('.FormPanel').each(function () {
            new KenticoForm(this, false);
        });

        createAnchor.init();
        resize.init();
        calculator.init();
        climateSlider.init();

        let embedParam = General.getParameterByName('embed');
        if (embedParam !== null && embedParam !== '0') {
            embedModule.init();
        }

        htmlVideoPlayer.init();
        handleResponsiveBgImagesForSmallScreens();
        SuccessStoryFilter();
        lpShadow();
        subnavigation.init(false);

        window.marketoFileUpload = marketoFileUpload;


        overviewSubsection.init();

        webpImages();

        widget4ImageSubmenu();
        widgetSliderWithImages();

        /* eslint-disable */
        Sys.WebForms.PageRequestManager.getInstance().add_endRequest(() => {
            const postBackSourceElement = Sys.WebForms.PageRequestManager.getInstance()._postBackSettings.sourceElement;

            customForm = new CustomForm();
            newsList = new NewsList();
            lazyLoad = new LazyLoad();
            customDropdown = new CustomDropdown();
            customForm.init();
            newsList.init();
            productFilter.init();

            //because usercentrics does a postback at the page load, this init was called twice, adding onclick event to each button twice. thus... this.
            if ($(postBackSourceElement).parents(".product-filter").length > 0) {
                compareLane.init();
            }

            downloadTracker.init();
            customDropdown.init();

            if ($(postBackSourceElement).is('input[id*="viewBiz_btnOK"][type="submit"]') && $('[id*="viewBiz_pM_lS"]').length > 0) {
                const successMessageOffsetTop = $('[id*="viewBiz_pM_lS"]').offset().top + $('[id*="viewBiz_pM_lS"]').innerHeight() / 2 - $(window).height() / 2;
                window.scrollTo(0, successMessageOffsetTop);
            }

            $('.FormPanel').each(function () {
                if ($(this).attr('id') === $(postBackSourceElement).parents('.FormPanel').attr('id')) {
                    new KenticoForm(this, true);
                } else {
                    new KenticoForm(this, false);
                }
                KenticoFormValidationErrors.checkValidationPosition();
            });

            createAnchor.init();
            linkCleaner.init();
            lazyLoad.init();
            homeSelect.init();

            // If postback on quickcheck form
            /*
             ** LP Quick check
             */
            const documentCookies = document.cookie.split(';');
            let quickcheckMessageId = '';
            for (let i = 0; i < documentCookies.length; i++) {
                const cookie = documentCookies[i];
                if (cookie.indexOf('quickcheckMessageId') > -1) {
                    quickcheckMessageId = cookie.split('=')[1];
                    break;
                }
            }
            if (quickcheckMessageId !== "") {
                lpQuickCheck.showFormWithMessage({ messageId: quickcheckMessageId });
            }

            let quickCheckFormElem = $('.lp-quickcheck__box--form');
            if (quickCheckFormElem.hasClass('lp-quickcheck__box--active')) {
                lpQuickCheck.formValidation();
            }

            lpQuickCheck.bindAnswerButtonsClickEvent();
            embedParam = General.getParameterByName('embed');

            if (embedParam !== null && embedParam !== '0') {
                embedModule.init();
            }

            webpImages();
        });
        /* eslint-enable */
    });

    $(window).on('DOMContentLoaded load resize scroll', debounce(() => {
        multipleInstances.counterHandler
    }, 50));

    $(window).on('resize', debounce(() => {
        overviewSubsection.init();
        subnavigation.init(true);
    }, 100));

    $(window).on('DOMContentLoaded load', () => {
        General.scrollToAnchorOnLoad();
        hubspot();
    });

    $(window).on('DOMContentLoaded load ', debounce(() => {
        dynamicsForm();
    }, 50));

    overviewSubsection.init();

    // Mutation observer for the Hubspot iframe

    const mutationTargetNode = document.querySelectorAll('.hbspt-form');

    const mutationConfig = {
        attributes: true,
        childList: true,
        subtree: true
    };

    const mutationCallback = function (mutationList) {
        // Use traditional 'for loops' for IE 11
        for (const mutation of mutationList) {
            if (mutation.target.classList.contains('hs-form-iframe')) {
                hubspot();
            }
        }
    };

    const iframeObserver = new MutationObserver(mutationCallback);
    mutationTargetNode.forEach(targetNode => {
        iframeObserver.observe(targetNode, mutationConfig)
    }
    );

};

export default modules;
