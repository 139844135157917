class dokoniFindCalculator {
    init() {
        this.setupEvents();
        this.recalculate();
    }

    setupEvents() {
        const elemsToMonitor = $('.js-minutes-searching, .js-employees-count, .js-income-per-hour');
        elemsToMonitor.on('keyup change', this.recalculate);
    }

    recalculate() {

        function getEpsilonForIE() {
            let x = 1.0;
            while ((1.0 + 0.5 * x) !== 1.0)
                x *= 0.5;
            return x;
        }
        const minutesPerDayElem = $('.js-minutes-per-day');
        const hoursPerWeekElem = $('.js-hours-per-week');
        const searchingPerYearElem = $('.js-searching-per-year');
        // If broswer doesn't support Epsilon (IE), it uses function instead
        const numberEpsilon = Number.EPSILON || getEpsilonForIE();

        let minutesSearching = $('.js-minutes-searching').val();
        let employeeCount = $('.js-employees-count').val();
        let incomePerHour = $('.js-income-per-hour').val();

        let minPerDay = minutesSearching * employeeCount;
        let hoursPerWeek = (minPerDay * 5) / 60;
        let searchingPerYear = minPerDay / 60 * incomePerHour * 252;

        let minPerDayText = (Math.round((minPerDay + numberEpsilon) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        let hoursPerWeekText = (Math.round((hoursPerWeek + numberEpsilon) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        let searchingPerYearText = (Math.round((searchingPerYear + numberEpsilon) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        minutesPerDayElem.text(minPerDayText);
        hoursPerWeekElem.text(hoursPerWeekText);
        searchingPerYearElem.text(searchingPerYearText);
    }
}

export default dokoniFindCalculator;