class LinkCleaner {

    init() {
        this.cleanLinks();
    }

    cleanLinks() {
        $('a').each(function () {
            const self = $(this);
            const attr = self.attr('href');
            if (attr !== undefined) {
                if (attr.indexOf('/CMSPages/') !== -1) {
                    const replacedAttr = self.attr('href').replace("/CMSPages/", "");
                    self.attr('href', replacedAttr);
                }
            }
        });
    }
}

export default LinkCleaner;
