import General from '../general/general';

class ImageSwiper {
    general = new General;

    init() {
        this.swiperInit();
    }

    swiperInit() {
        const that = this;
        $('.image-swiper').each(function () {
            const swiperContainer = $(this).find('.swiper-container');
            const swiperOptions = {
                observer: true,
                observeParents: true,
                slidesPerView: 3,
                spaceBetween: 20,
                breakpoints: {
                    1024: {
                        slidesPerView: 2
                    },
                    600: {
                        slidesPerView: 1
                    },
                },
                navigation: {
                    nextEl: $(this).find('.image-swiper__btn-next'),
                    prevEl: $(this).find('.image-swiper__btn-prev')
                },
                on: {
                    init: function () {
                        const slider = $(this)[0];
                        const slides = slider.slides;
                        const overlay = slider.$el.parents('.overlay-content__border-wrapper');
                        const nextEl = slider.$el.parent().find('.image-swiper__btn-next');
                        const prevEl = slider.$el.parent().find('.image-swiper__btn-prev');
                        let imageEl = slides.length > 0 ? slides[0].querySelector('.image-swiper__item') : null;
                        let imageHeight = imageEl ? imageEl.clientHeight : 0;
                        let hasDescription;
                        let shouldPreformeNavigationPosition = false;

                        slides.each(function () {
                            const self = $(this);
                            const linkEl = self.children('a');
                            if (linkEl.length === 0) {
                                self.on('click', function () {
                                    const target = $(this).data('target');
                                    if (target) {
                                        $(target).modal('show');
                                    }

                                    if (overlay.length > 0) {
                                        overlay.css('zIndex', 'unset');
                                    }
                                })
                            } else {
                                const href = linkEl.attr('href');
                                const linkRegex = /^((http|https|ftp):\/\/)/;
                                const linkIsValid = href ? (href !== '#' || linkRegex.test(href)) : false;

                                if (href && !linkIsValid) {
                                    self.on('click', function (e) {
                                        e.preventDefault();
                                    })
                                }
                            }
                            if (!hasDescription) {
                                hasDescription = self.find('.overview-subsection__item-box').length > 0;
                                shouldPreformeNavigationPosition = hasDescription;
                            }
                        });

                        if (shouldPreformeNavigationPosition && hasDescription) {
                            nextEl.css({
                                top: `calc(${imageHeight / 2}px)`
                            });
                            prevEl.css({
                                top: `calc(${imageHeight / 2}px)`
                            });
                            shouldPreformeNavigationPosition = !shouldPreformeNavigationPosition;
                        }

                        // Add additional event listener to handle the overlay
                        if (overlay.length > 0) {
                            $('.custom-modal').on('click', function () {
                                overlay.css('zIndex', '');
                            })
                        }

                    }
                }
            }
            that.general.initSwiper(swiperContainer, swiperOptions);
        });
    }
}

export default ImageSwiper;
