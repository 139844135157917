class HomeSelect {
    
    init () {
        const $sourceDropdown = $('.js-wheel-config');
        const $targetDropdown = $('.js-wheel-selector');
        const $targetButton = $('.js-wheel-go-btn');

        this.apiConfig($sourceDropdown, $targetDropdown);
        this.onDropdownChanged($targetDropdown, $targetButton);

        if ($sourceDropdown.find(":selected").index() > 0) {
            const selectedValueId = $sourceDropdown.find(":selected").val();
            const culture = $sourceDropdown.data('lang');
            $.get(`/cmspages/dd/homepageselectservice.ashx?parentId=${selectedValueId}&lang=${culture}`, null, function (e) {
                $targetDropdown.children().remove().end();
                e.forEach(function (item, index) {
                    $targetDropdown.append(`<option value="${item.Value}" ${index == 0 ? "selected=\"selected\"" : ""} data-new-tab="${item.OpenInNewTab}">${item.Name}</option>`)
                });
                $targetDropdown.trigger('change');
            });
        }
    }

    apiConfig($sourceDropdown, $targetDropdown) {
        $sourceDropdown.on('change', function () {
            var parentId = $(this).val();
            var culture = $(this).data('lang');
            $(this).trigger('blur').trigger('focus');
            $.get(`/cmspages/dd/homepageselectservice.ashx?parentId=${parentId}&lang=${culture}`, null, function (e) {
                $targetDropdown.children().remove().end();
                e.forEach(function (item, index) {
                    $targetDropdown.append(`<option value="${item.Value}" ${index == 0 ? "selected=\"selected\"" : ""} data-new-tab="${item.OpenInNewTab}">${item.Name}</option>`)
                });
                $targetDropdown.trigger('change');
            }); 
        })
    }

    onDropdownChanged($targetDropdown, $targetButton) {
        $targetDropdown.on('change', function () {
            const selectedOption = $targetDropdown.find(":selected");
            $targetButton.attr('href', selectedOption.val());
            $targetButton.attr('target', selectedOption.attr('data-new-tab') === "true" ? "_blank" : "_self");
            $(this).trigger('blur').trigger('focus');
        });
        // set value on first load
        $targetButton.attr('href', $targetDropdown.val());
        $targetButton.attr('target', $targetDropdown.find(":selected").attr('data-new-tab') === "true" ? "_blank" : "_self");
    }
}

export default HomeSelect;
