import General from '../general/general';

class ProductServicesSwiper {
    general = new General;
    productInfoSliderOptions = {
        observer: true,
        observeParents: true,
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: {
            el: '.product-services-swiper__pagination',
            bulletClass: 'swiper-bullet',
            bulletActiveClass: 'swiper-bullet--active',
        },
        breakpoints: {
            1025: {
                spaceBetween: 30,
                slidesPerView: 2
            },
            661: {
                slidesPerView: 1
            }
        }
    };

    swiperInit(el) {
        const nextEl = el.next('.swiper-nav-buttons');
        this.productInfoSliderOptions.navigation = {
            nextEl: nextEl.find('.swiper-button-next'),
            prevEl: nextEl.find('.swiper-button-prev')
        };
        this.general.initSwiper(el, this.productInfoSliderOptions);
    }
}

export default ProductServicesSwiper;
