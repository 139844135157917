class NewsList {
    searchInputNews = $('.search--news .searchBox input[type="submit"]');
    searchInputNewsPlaceholder = $('.search--news .searchBox input[data-placeholder]');
    searchInputTextNews = $('.search--news .searchBox input[type="text"]');
    searchInputOpenClass = 'searchBox--open';
    loadMoreButton = $('.news-list .js-load-more');
    formSubmitted = false;

    init() {
        this.searchPlaceholder();
        this.onTextInputDelete();
        this.handleSearchOnEnter();
        this.loadMore();
    }

    searchPlaceholder() {
        const currentEl = this.searchInputNewsPlaceholder;
        currentEl.each(function () {
            const self = $(this);
            const searchInputNewsPlaceholderText = self.attr('data-placeholder');
            const placeholderEl = `
                                    <span class="searchBox__placeholder">
                                        ${searchInputNewsPlaceholderText}
                                    </span>
                                `;
            const searchBox = self.parents('.searchBox');
            const placeholderHTMLEl = document.createRange().createContextualFragment(placeholderEl);
            searchBox.prepend(placeholderHTMLEl);
        });
        const searchBoxPlaceholder = $('.searchBox__placeholder');
        searchBoxPlaceholder.on('click', function () {
            const self = $(this);
            self.addClass('searchBox__placeholder--hidden');
            self.siblings('input[data-placeholder]').focus();
        });

        //Emergency fix - shoki - check if it's defined
        if (currentEl !== undefined) {
            if (currentEl.val() !== undefined && currentEl.val().length !== undefined) {
                (currentEl.val().length > 0)
                    ? currentEl.siblings('.searchBox__placeholder').addClass('searchBox__placeholder--hidden')
                    : '';
            }

            currentEl.on('blur', function () {
                const self = $(this);
                (self.val().length === 0)
                    ? self.siblings('.searchBox__placeholder').removeClass('searchBox__placeholder--hidden')
                    : '';
            });
            currentEl.on('focus', function () {
                const self = $(this);
                self.siblings('.searchBox__placeholder').addClass('searchBox__placeholder--hidden');
            });
        }
    }

    onTextInputDelete() {
        const that = this;
        this.searchInputTextNews.on('input', function (e) {
            if (e.target.value.trim().length === 0) {
                that.searchInputNews.trigger('click');
            }
        })
    }

    handleSearchOnEnter() {
        const submitBtn = this.searchInputNews;
        this.searchInputTextNews.on('keydown', function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                submitBtn.trigger('click');
            }
        });
    }

    static updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    }

    loadMore() {
        this.loadMoreButton.on('click', function () {
            const fetchNewsItems = $(this).data('fetch');
            if (history.pushState) {
                const { href } = location;
                const { hash } = location;

                let separator = "?";

                if (href.indexOf('?') != '1') {
                    separator = "&";
                }

                const newUrl = NewsList.updateQueryStringParameter(href, "CurrentTopN", fetchNewsItems);
                history.pushState({ path: newUrl }, '', newUrl);
            }
        });
    }


}

export default NewsList;
