import VimeoVideoPlayer from "../video-player/vimeo-video-player";
import EventSpeakersSwiper from "../event-speakers/event-speakers";
import FullWidthSlider from '../full-width-slider/full-width-slider';
import SolutionTabs from '../solution-tabs/solution-tabs';
import EventSwiper from '../event-swiper/event-swiper';
import ProductServicesSwiper from '../product-services-swiper/product-services-swiper';
import HomeIntroNavSwiper from '../home-intro/home-intro-nav-swiper';
import HomeIntroTabSwiper from '../home-intro/home-intro-tab-swiper';
import General from '../general/general';
import Counter from '../counter/counter';
import LightBox from '../lightbox/lightbox';
import YoutubeVideoPlayer from '../video-player/yt-video-player';
const debounce = require('debounce');

class MultipleInstances {
    initialLoad = false;

    init() {
        this.videoInstances();
        this.solutionTabsSliders();
        this.fullWidthSliders();
        this.eventSpeakersSliders();
        this.homeEventsSliders();
        this.productServicesSliders();
        this.homeIntroNavSliders();
        this.homeIntroTabSliders();
        this.galleryInit();
        this.animateIcons();
        this.toggleVideoPlayback();
        this.fixVideoDimensions();

        const that = this;
        if ('addEventListener' in window) {
            const handleResize = debounce(function () {
                that.toggleVideoPlayback();
                that.fixVideoDimensions();
            }, 100);
            window.addEventListener('resize', handleResize);
        }
    }

    videoInstances() {
        const vimeoVideos = $('[vimeo-video]');
        const ytVideos = $('[yt-video]');

        if (vimeoVideos.length > 0) {
            vimeoVideos.each((i, el) => {
                const id = $(el).children('[data-vimeo-url]').attr('id');
                const durationEl = $(el).next().find('.js-video-duration');

                new VimeoVideoPlayer(id, durationEl).init();
            });
        }

        if (ytVideos.length > 0) {
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            new YoutubeVideoPlayer(ytVideos).init();
        }
    }

    eventSpeakersSliders() {
        const eventSpeakersSliders = $('.event-speakers__swiper-container');

        if (eventSpeakersSliders.length > 0) {
            eventSpeakersSliders.each((i, el) => {
                new EventSpeakersSwiper().swiperInit($(el));
            });
        }
    }

    solutionTabsSliders() {
        const solutionTabs = new SolutionTabs();
        const solutionTabsSliders = $('.solution-tabs__slider-container');

        if (solutionTabsSliders.length > 0) {
            solutionTabsSliders.each((i, el) => {
                solutionTabs.swiperInit($(el));
            })
        }
    }

    fullWidthSliders() {
        const fullWidthSlider = new FullWidthSlider();
        const fullWidthSliders = $('.full-width-slider__container');

        if (fullWidthSliders.length > 0) {
            fullWidthSliders.each((i, el) => {
                fullWidthSlider.swiperInit($(el));
            })
        }
    }

    homeEventsSliders() {
        const homeEventsSlider = new EventSwiper();
        const homeEventsSliders = $('.event-swiper .swiper-container');

        if (homeEventsSliders.length > 0) {
            homeEventsSliders.each((i, el) => {
                homeEventsSlider.swiperInit($(el));
            })
        }
    }

    productServicesSliders() {
        const productServicesSlider = new ProductServicesSwiper();
        const productServicesSliderSliders = $('.product-services-swiper__container');

        if (productServicesSliderSliders.length > 0) {
            productServicesSliderSliders.each((i, el) => {
                productServicesSlider.swiperInit($(el));
            })
        }
    }

    homeIntroNavSliders() {
        const homeIntroNavSlider = new HomeIntroNavSwiper();
        const homeIntroNavSliders = $('.home-intro__nav-swiper-container--initializer');

        if (homeIntroNavSliders.length > 0) {
            homeIntroNavSliders.each((i, el) => {
                homeIntroNavSlider.swiperInit($(el));
            })
        }
    }

    homeIntroTabSliders() {
        const homeIntroTabSlider = new HomeIntroTabSwiper();
        const homeIntroTabSliders = $('.home-intro__tabs-swiper-container');

        if (homeIntroTabSliders.length > 0) {
            homeIntroTabSliders.each((i, el) => {
                homeIntroTabSlider.swiperInit($(el));
            })
        }
    }

    counterHandler() {
        const general = new General();
        const counter = new Counter();
        const counterWrapper = $('.js-counter');

        if (general.isElementInViewport(counterWrapper) && !this.initialLoad) {
            const items = $('.js-counter h3');
            items.each((i, item) => {
                counter.init(item);
            });
            this.initialLoad = true;
        }
    };

    toggleVideoPlayback() {
        const screenXsMax = window.matchMedia('(min-width: 767px)');
        const videoWrappers = $('.js-custom-video');
        videoWrappers.each((i, el) => {
            const video = $(el).children('video');
            if (screenXsMax.matches) {
                const promise = video[0].play();
                if (promise !== undefined) {
                    promise.then(_ => {
                        video.trigger('play');
                    }).catch(error => {
                        console.log('Autoplay was prevented');
                        console.log(error);
                        video.trigger('play');
                    });
                }
            } else {
                const promise = video[0].pause();
                if (promise !== undefined) {
                    promise.then(_ => {
                        video.trigger('pause');
                    }).catch(error => {
                        console.log('Pausing was prevented');
                        console.log(error);
                        video.trigger('pause');
                    });
                }
            }
        });
    }

    fixVideoDimensions() {
        const videoWrappers = $('.js-custom-video');
        videoWrappers.each((i, el) => {
            const video = $(el).children('video');
            const wrapperAspectRatio = $(el).innerHeight() / $(el).innerWidth();
            let videoAspectRatio = video[0].videoHeight / video[0].videoWidth;
            if (video[0].videoWidth === 0 && video[0].videoHeight === 0) {
                const getVideoAspectRatio = setInterval(getVideoDimensions, 50);
                function getVideoDimensions() {
                    if (video[0].videoWidth > 0 && video[0].videoHeight > 0) {
                        clearInterval(getVideoAspectRatio);
                        videoAspectRatio = video[0].videoHeight / video[0].videoWidth;
                        if (wrapperAspectRatio < videoAspectRatio) {
                            video.css({
                                maxWidth: "100%",
                                maxHeight: "none"
                            })
                        } else {
                            video.css({
                                maxWidth: "none",
                                maxHeight: "100%"
                            })
                        }

                    }
                }
            } else {
                if (wrapperAspectRatio < videoAspectRatio) {
                    video.css({
                        maxWidth: "100%",
                        maxHeight: "none"
                    })
                } else {
                    video.css({
                        maxWidth: "none",
                        maxHeight: "100%"
                    })
                }
            }
        });
    }

    // There is a Bootstrap modal window that opens this gallery!
    galleryInit() {
        //     const galleryWrapper = $('.js-gallery-wrapper');
        //     const lightBox = new LightBox();

        //     if (galleryWrapper.length > 0) {
        //         galleryWrapper.each((i, el) => {
        //             const elSelector = '#' + el.id + ' a';
        //             lightBox.lightboxInit(elSelector);
        //         })
        //     }
    }

    animateIcons() {
        const animatableIcons = $('.js-animatable-icon');

        if (animatableIcons.length > 0) {
            const loadedAnimations = [];

            const startAnimation = (animatableEl) => {
                const animatableElClasses = animatableEl.className;

                loadedAnimations.forEach(function (animationEl) {
                    const currClasses = animationEl.wrapper.className;

                    if (animatableElClasses === currClasses) {
                        animationEl.goToAndPlay(5, true);
                    }
                });
            };
        }
    }
}

export default MultipleInstances;
