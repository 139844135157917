import General from '../general/general';

class ProductInfoSwiper {
    general = new General;
    productInfoSwiperEl = $('.product-info-swiper__container');

    productInfoSliderContainer = '.product-info-swiper__container';
    productInfoSliderOptions = {
        slidesPerView: 4,
        spaceBetween: 2,
        pagination: {
            el: '.product-info-swiper__pagination',
            bulletClass: 'swiper-bullet',
            bulletActiveClass: 'swiper-bullet--active',
            clickable: true
        }
    };

    init() {
        if (this.productInfoSwiperEl.length > 0) {
            this.swiperInit();
        }
    }

    swiperInit() {
        this.general.initSwiper(this.productInfoSliderContainer, this.productInfoSliderOptions)
    }
}

export default ProductInfoSwiper;
