const debounce = require('debounce');

class ProductSpecifications {

    init() {
        $($('.product-specifications .nav-link')[0]).addClass('active');
        $($('.product-specifications .dropdown-menu .nav-link')[0]).addClass('active');
        $($('.product-specifications .tab-content .tab')[0]).addClass('active');

        this.addGreyStripes();
        this.dropdownHandler();
        this.onNavLinksClick();
        const that = this;

        if ('addEventListener' in window) {
            const handleResize = debounce(function () {
                that.addGreyStripes();
            }, 100);
            window.addEventListener('resize', handleResize);
        }
    }

    addGreyStripes() {
        const lists = $('.product-specifications__list');
        lists.each(function () {
            const list = $(this);
            if (list.parents('.tab').hasClass('active')) {
                const items = list.find('li');
                const listOffsetTop = Math.floor(list[0].getBoundingClientRect().top);
                items.removeClass('grey-stripe');
                items.each(function () {
                    const item = $(this);
                    const itemOffsetTop = Math.floor(item[0].getBoundingClientRect().top);
                    const isAtTheTop = listOffsetTop - itemOffsetTop === 0;
                    if (isAtTheTop) {
                        item.addClass('grey-stripe');
                    } else {
                        if (!item.prev().hasClass('grey-stripe')) {
                            item.addClass('grey-stripe');
                        }
                    }
                })
            }
        })
    }

    dropdownHandler() {
        const button = document.querySelectorAll(".custom-dropdown__product-specifications--button")[0];
        const dropdown = document.getElementsByClassName('custom-dropdown__product-specifications--menu')[0];
        if (dropdown) {
            const dropdownItems = dropdown.getElementsByTagName("a");
            const initialSelection = dropdownItems[0];

            button.getElementsByTagName("span")[0].innerText = initialSelection.innerText;

            for (let i = 0; i < dropdownItems.length; ++i) {
                dropdownItems[i].addEventListener('click', () => {
                    button.getElementsByTagName("span")[0].innerText = dropdownItems[i].innerText;
                });
            }
        }
    }

    onNavLinksClick() {
        const addGreyStripes = this.addGreyStripes;
        $('.product-specifications .nav-link').click(function () {
            const targetTabId = $(this).attr('data-tab-id');
            $(`.tab-content #${targetTabId}`).addClass('active').siblings().removeClass('active');
            $('.nav-link').removeClass('active');
            $(`.nav-link[data-tab-id=${targetTabId}]`).addClass('active');
            addGreyStripes();
        });
    }
}

export default ProductSpecifications;
