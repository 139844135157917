class HomeIntro {
    init() {
        this.toggleTabs();
    }

    highlightClickedLink(clickedLink, otherLinks) {
        otherLinks.addClass('inactive');
        clickedLink.removeClass('inactive');
    }

    toggleTabs() {
        const tabToggleBtns = $('.js-intro-link');
        const tabs = $('.js-intro-tab');
        const tabsContainer = $('.js-intro-tabs-container');
        const introNav = $('.js-intro-nav');

        tabToggleBtns.on('click', (e) => {
            const self = $(e.currentTarget);
            const openTabId = self.attr('toggle-tab-id');

            e.preventDefault();

            this.highlightClickedLink(self, tabToggleBtns);

            tabs.each(function () {
               const self = $(this);
               const tabId = self.attr('tab-id');

               if (tabId === openTabId) {
                   self.addClass('home-intro__tabs-tab--active');
               } else if (self.hasClass('home-intro__tabs-tab--active')) {
                   self.removeClass('home-intro__tabs-tab--active');
               }
            });

            introNav.addClass('home-intro__nav--active');
            tabsContainer.addClass('home-intro__tabs--visible');
        });
    }
}

export default HomeIntro;
