import General from '../general/general';

class SolutionTabs {
    general = new General;
    solutionSliderOptions = {
        observer: true,
        observeParents: true,
        slidesPerView: 2,
        spaceBetween: 65,
        pagination: {
            el: '.solution-tabs__slider-pagination',
            bulletClass: 'swiper-bullet',
            bulletActiveClass: 'swiper-bullet--active',
        },
        breakpoints: {
            768: {
                slidesPerView: 1,
                spaceBetween: 0
            },
        }
    };

    swiperInit(el) {
        const nextEl = el.next('.swiper-nav-buttons');
        this.solutionSliderOptions.navigation = {
            nextEl: nextEl.find('.swiper-button-next'),
            prevEl: nextEl.find('.swiper-button-prev')
        };
        this.general.initSwiper(el, this.solutionSliderOptions);
    }
}

export default SolutionTabs;
