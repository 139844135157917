import General from "../general/general";

class titleHeaderMobile {
    init() {
        const isMobile = General.isMobile();
        const textSourceDesktop = $('.js-desktop-seo');
        const textDestinationMobile = $('.js-mobile-seo');
        const copyDesktopToMobile = this.copyDesktopToMobile;

        if (textSourceDesktop.length && textDestinationMobile.length) {
            if (isMobile) {
                copyDesktopToMobile(textSourceDesktop, textDestinationMobile);
                textSourceDesktop.empty();
            }
        }
    }

    copyDesktopToMobile(textSourceDesktop, textDestinationMobile) {
        textDestinationMobile.append(textSourceDesktop.html());
    }
}

export default titleHeaderMobile;
