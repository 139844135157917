import General from '../general/general';

class VideoSwiper {
    general = new General;
    videoSlide = $('.video-swiper__container .swiper-slide');

    init() {
        this.swiperInit();
        this.onSliderClick();
        this.pauseVideos();
    }
    swiperInit(el) {
        const that = this;

        $('.video-slider').each(function () {
            const swiperContainer = $(this).find('.video-swiper__container');
            const swiperOptions = {
                slidesPerView: 'auto',
                spaceBetween: 2,
                navigation: {
                    nextEl: $(this).find('.swiper-button-next-video'),
                    prevEl: $(this).find('.swiper-button-prev-video')
                }
            }
            if (swiperContainer.length > 0) {
                that.general.initSwiper(swiperContainer, swiperOptions);
            }

            const swiperGalleryContainer = $(this).find('.product-gallery-swiper-video');
            const swiperIsInModal = swiperGalleryContainer.length > 0 ? swiperGalleryContainer.parents('.modal') : false;
            let modalParent;

            if (swiperIsInModal) {
                modalParent = swiperGalleryContainer.parents('.modal');
                if (modalParent) {
                    modalParent.css({
                        'display': 'block',
                        'opacity': '1',
                        'position': 'absolute',
                        'top': '-9999px',
                        'left': '-9999px'
                    })
                }

            }
            const swiperGalleryOptions = {
                slidesPerView: 'auto',
                spaceBetween: 2,
                navigation: {
                    nextEl: $(this).find('.swiper-button-next-video-gallery'),
                    prevEl: $(this).find('.swiper-button-prev-video-gallery')
                }
            }
            if (swiperGalleryContainer.length > 0) {
                that.general.initSwiper(swiperGalleryContainer, swiperGalleryOptions);
                if (modalParent) {
                    modalParent.css({
                        'display': 'none',
                        'opacity': 'initial',
                        'position': 'fixed',
                        'top': '0',
                        'left': '0'
                    })
                }
            }
        });
    }
    onSliderClick() {
        this.videoSlide.on('click', function () {
            const videoGallerySwiper = document.querySelector('.product-gallery-swiper-video').swiper;
            const self = $(this);
            const clickedSlideIndex = self.attr('slide-index');
            videoGallerySwiper.slideTo(clickedSlideIndex, 0);
        })
    }
    pauseVideos() {
        const videoGalleryModal = $('.custom-modal--product-gallery');
        const closeModal = $('.modal');
        $('.modal .close').on('click', function () {
            $(this).parents('.modal').trigger('click');
        });
        const swiperButtons = 'swiper-nav-button--prod-detail';
        closeModal.on('click', function (e) {
            if ((e.target.tagName.toLowerCase() !== "video")) {
                if (videoGalleryModal.find('video').length) {
                    videoGalleryModal.find('video')[0].pause();
                }
            }
            if (!(e.target.classList.contains(swiperButtons))) {
                const videoGalleryYT = videoGalleryModal.find('iframe');
                videoGalleryYT.each(function (index) {
                    const pauseOnClickLink = videoGalleryYT[index].getAttribute('yt-data');
                    $(this).attr('src', pauseOnClickLink);
                });
            };
        });
    }
}

export default VideoSwiper;
