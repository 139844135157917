class VideoDescriptionLimit {
    videoSliderDescription = $('.video-slider__slide-description');

    init() {
        this.truncateText();
    }

    truncateText() {
        const characterLimit = 140;
        this.videoSliderDescription.each(function () {
            const textExtracted = $(this).text().replace(/\s\s+/g, ' ');
            if (textExtracted.length > characterLimit) {
                $(this).text(textExtracted.substring(0, characterLimit) + '...');
            }
        });
    }
}

export default VideoDescriptionLimit;