class userCentrics {

    init() {
        this.initEvents();
    }

    readConsents(denyButtonElem, essentialButtonElem, allButtonElem) {
        let acceptedConsentCategories = [];
        const services = UC_UI.getServicesBaseInfo(); //After UC is initialized you can retrieve the services information, by using one of the following methods: getServicesBaseInfo or getServicesFullInfo. The method getServices was deprecated in the version 2.2.0-beta.3 and it will be deleted in version 3.0, so we advise you to update to these new methods, based in your needs:

        for (var i = 0; i < services.length; i++) {
            var elem = services[i];
            if (elem.categorySlug !== undefined
                && elem.categorySlug !== ''
                && elem.consent.status === true) {
                acceptedConsentCategories.push(elem.categorySlug);
            }
        };

        const marketing = (element) => element == 'marketing';
        const essential = (element) => element == 'essential';

        if (acceptedConsentCategories.some(marketing)) {
            if (allButtonElem)
                allButtonElem.click();
        } else if (acceptedConsentCategories.some(essential)) {
            if (essentialButtonElem)
                essentialButtonElem.click();
        } else {
            if (denyButtonElem)
                denyButtonElem.click();
        }
    }

    initEvents() {
        const readConsents = this.readConsents;

        const denyButtonElem = $('.js-uc-deny')[0];
        const essentialButtonElem = $('.js-uc-essential')[0];
        const allButtonElem = $('.js-uc-all')[0];

        window.addEventListener('UC_UI_INITIALIZED', function (event) {
            readConsents(denyButtonElem, essentialButtonElem, allButtonElem);
        });

        // https://docs.usercentrics.com/#/consent-data-exchange
        window.addEventListener('Window Event Layer', function (e) {
            if (window.UC_UI === undefined) {
                // no usercentrics object in window, initialize event should handle this
                return;
            }

            if (e.detail && e.detail.event == 'consent_status') {
                readConsents(denyButtonElem, essentialButtonElem, allButtonElem);
            }
        });
    }

}

export default userCentrics;