import Swiper from 'swiper/dist/js/swiper.js';
const debounce = require('debounce');

const swiperInstance = function () {
// Top 3 articles mobile swiper
    let topArticlesSwiper;
    
    if($('.js-top-articles').length) {
        const isMobile = window.matchMedia('(max-width: 600px)').matches;
        if (isMobile && !topArticlesSwiper ) {
            topArticlesSwiper = topArticlesSwiper = initSwiper();
        } else if (!isMobile && topArticlesSwiper) {
            topArticlesSwiper.destroy();
            topArticlesSwiper = null;
        }

        $(window).on('resize', debounce(function () {
            const isMobile = window.matchMedia('(max-width: 600px)').matches;
            if (isMobile && !topArticlesSwiper ) {
                topArticlesSwiper = initSwiper();
            } else if (!isMobile && topArticlesSwiper) {
                topArticlesSwiper.destroy();
                topArticlesSwiper = null;
            }
        }, 50));
    }
    
    function initSwiper () {
        return new Swiper($('.js-top-articles').find('.swiper-container'), {
            navigation: {
                nextEl: $('.js-top-articles').find('.swiper-button-next'),
                prevEl: $('.js-top-articles').find('.swiper-button-prev')
            },
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 20,
        });  
    }

// List Widget mobile swiper
    $('.list-widget').each(function () {
        const listWidget = $(this);
        const lists = listWidget.find('ul, ol');
        lists.each(function () {
            const list = $(this);
            if (list.parents('ol').length === 0 && list.parents('ul').length === 0) {
                list.children('li').addClass('swiper-slide');
                list.addClass('swiper-wrapper');
            }
        });
        initListWidgetSwiper(listWidget);

    });

    function initListWidgetSwiper (listWidget) {
        return new Swiper(listWidget.find('.swiper-container'), {
            navigation: {
                nextEl: listWidget.find('.swiper-button-next'),
                prevEl: listWidget.find('.swiper-button-prev')
            },
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 20,
            autoHeight: true,
            allowTouchMove: window.matchMedia('(max-width: 600px)').matches ? true : false,
            on: {
                resize: function () {
                    const isMobile = window.matchMedia('(max-width: 600px)').matches;
                    const swiperInstance = listWidget.find('.swiper-container')[0].swiper;
                    if (isMobile) {
                        swiperInstance.allowTouchMove = true;
                    } else {
                        swiperInstance.allowTouchMove = false;
                    }
                }
            }

        });  
    }
}    

export default swiperInstance;