class Popup {
	popup = $('.popup');
	body = $('body');
	popupOpenTrigger = $('.js-open-popup');
	popupCloseIcon = $('.popup__close');

	open(targetPopup) {
		const { body } = this;
		targetPopup.addClass('popup--open');
		body.addClass('popup-open');
	}

	close() {
		const { popup, body } = this;
		popup.removeClass('popup--open');
		setTimeout(function () {
			body.removeClass('popup-open');
		}, 200);
	}

	bindClickEvents() {
		const { popupOpenTrigger, popupCloseIcon, body } = this;
		const that = this;

		popupOpenTrigger.on('click', function (e) {
			e.stopPropagation();
			const popupId = $(this).data('popup-id') || $(this).closest('[data-popup-id]').data('popup-id');
			if (popupId !== undefined) {
				that.open($(`.popup#${popupId}`));
			} else {
				that.open($(this).closest('.js-popup').find('.popup'));
			}
		});

		popupCloseIcon.on('click', function (e) {
			e.stopPropagation();
			that.close();
		});

		body.click(function (e) {
			if (that.popup.attr("data-close-on-click-outside") !== "false") {
				const target = $(e.target);
				if (!target.hasClass('popup__content') && !$('.popup__content').find(target).length && $('.popup').hasClass('popup--open')) {
					that.close();
				}
			}
			
		});
	}

	init() {
		this.bindClickEvents();
	}
}

export default Popup;
