import KenticoFormValidationErrors from './kentico-form-validation-errors';

class KenticoForm {

    constructor(formElem, setErrorsAfterPostback) {
        this.$element = $(formElem);
        this.validationParentSelector = 'div[id*="ContactValidation"], tr[id*="ContactValidation"]';
        this.formFieldLabelElem = $(formElem).find('.FieldLabel');
        this.inputElem = $(formElem).find('input[type=text], input[type=date], select, textarea');
        this.inputCheckbox = $(formElem).find('#ContactPreference').find('input[type=checkbox]');
        this.select = $(formElem).find('select');
        this.formHasInputErrors = false;
        this.formHasEmailErrors = false;
        this.formHasCheckboxErrors = false;
        this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.init(setErrorsAfterPostback);
    }

    labelCellElemActiveClass = 'FieldLabel--active';

    init(setErrorsAfterPostback) {
        if (this.$element.find('form.mktoForm').length === 0) {
            this.fixFormLabelField();
            this.fixFormUploader();
            this.checkRequiredFields();
            this.onFormSubmit();
            this.checkHiddenFieldValidation();
            this.checkBoxValidation();
            this.wrapSubmitButton();
            this.wrapInputControls();
            this.wrapMktoInputs();
            this.mktoErrorHandlingHelper();
            setTimeout(this.realTimeValidation(), 50);
            this.hideCaptchaLabel();
            if (setErrorsAfterPostback) {
                this.setErrorsAfterPostback();
            }
        }
    }

    wrapMktoInputs() {
        const $mktoInput = $('.mktoForm input').not($('.mktoForm input[type="checkbox"], .mktoForm input[type="radio"], .mktoForm input[type="hidden"]'));
        $mktoInput.each(function () {
            const self = $(this);
            if (!self.parents('.EditingFormValueCell-mkto').length) {
                self.wrap('<span class="EditingFormValueCell-mkto"></span>');
            }
        });
    }

    mktoErrorHandlingHelper() {
        const $mktoRequiredInput = $('.mktoRequired');
        $mktoRequiredInput.parent().addClass('required');
    }

    wrapInputControls() {
        const $checkbox = this.$element.find('input[type="checkbox"]');
        const $radio = this.$element.find('input[type="radio"]');
        const $checkboxLabel = this.$element.find('.mktoCheckboxList label, .checkbox label');
        const $radioLabel = this.$element.find('.mktoRadioList label, .radio label');
        
        this.select.each(function () {
            const self = $(this);
            self.parents('.EditingFormValueCell').addClass('EditingFormValueCell--dropdown');
        });

        $checkbox.each(function () {
            const self = $(this);
            if (!self.parents('.checkbox-element').length && !self.is(':checked')) {
                self.wrap('<span class="checkbox-element"></span>');
            } 
            else if (!self.parents('.checkbox-element').length && self.is(':checked')) {
                self.wrap('<span class="checkbox-element checkbox-element--active"></span>');
            }
        });

        $checkboxLabel.each(function () {
            const self = $(this);
            if (!self.parents('.checkbox-label').length) {
                self.wrap('<span class="checkbox-label"></span>');
            }
            self.parents('.checkbox-label').prev('.checkbox-element').wrap('<span class="checkbox-wrapper"></span>');
            self.parents('.checkbox-label').prev('.EditingFormValueCell').addClass('checkbox-wrapper');
        });
        
        $checkbox.on('change', function () {
            const self = $(this);
            if (self.is(":checked")) {
                self.parents('.checkbox-element').addClass('checkbox-element--active');
            } else {
                self.parents('.checkbox-element').removeClass('checkbox-element--active');
            }
        });

        $radioLabel.each(function () {
            const self = $(this);
            if (!self.parents('.radio-label').length) {
                self.wrap('<span class="radio-label"></span>');
            }
            self.parents('.radio-label').prev('.radio-element').wrap('<span class="radio-wrapper"></span>');
            self.parents('.radio-label').prev('.EditingFormValueCell').addClass('radio-wrapper');
        });
        $radio.each(function () {
            const self = $(this);
            if (!self.parents('.radio-element').length && !self.is(':checked')) {
                self.wrap('<span class="radio-element"></span>');
            }
            else if (!self.parents('.radio-element').length && self.is(':checked')) {
                self.wrap('<span class="radio-element radio-element--active"></span>');
            }
        });
        $radio.on('change', function () {
            const self = $(this);
            const $siblingRadios = self.parents('.radio-list-vertical').find('input[type="radio"]');
            $siblingRadios.parents('.radio-element').removeClass('radio-element--active');

            if (self.is(":checked")) {
                self.parents('.radio-element').addClass('radio-element--active');
            }
        });
    }

    wrapSubmitButton() {
        const $submitButton = $('.kentico-form .FormButton.btn.btn-primary');
        $submitButton.each(function () {
            const self = $(this);
            if (!self.parents('.large-button').length) {
                self.wrap('<div class="large-button"></div>');
            }
        });
    }

    fixFormLabelField() {
        /**** check if FormLabel has a label as a child, if yes add a css class
         *** this way we are avoiding the problem where Kentico puts only span element into the FormLabel field */
        this.formFieldLabelElem.each(function () {
            /* Fix for LP pages */
            if ($(this).parent().find('select').length > 0) {
                $(this).addClass('FieldLabel--qc');
            }

            if (($(this).has('label').length > 0) && !($(this).hasClass('FieldLabel--qc'))) {
                $(this).addClass('FieldLabel--label');
                const calcExplanationHeight = ($(this).next('.EditingFormValueCell').find('.ExplanationText').height() / 2);
                $(this)[0].style.top = 'calc(50% - ' + calcExplanationHeight + 'px)';
            }
        });
    }

    setErrorsAfterPostback() {
        const that = this;
        let requiredElem = this.$element.find('.required');
        let requiredInputElem = requiredElem.find('input[type=text]:not([id*="Email"], .CaptchaTextBox), select, textarea');
        let requiredEmailElem = requiredElem.find('input[id*=Email]');
        let requiredCheckboxFields = this.$element.find('.requiredCheckbox');

        requiredInputElem.each(function () {
            that.fieldValidation(this);
        });

        requiredEmailElem.each(function () {
            that.fieldValidation(this, true);
        });

        requiredCheckboxFields.each(function () {
            that.fieldValidation(this, false, true);
        });
    }

    checkIfFormHasErrors() {
        const that = this;
        let requiredElem = this.$element.find('.required');
        let requiredInputElem = requiredElem.find('input[type=text]:not([id*="Email"], .CaptchaTextBox), select, textarea');
        let requiredEmailElem = requiredElem.find('input[id*=Email]');
        let requiredCheckboxFields = this.$element.find('.requiredCheckbox');

        // check errors for input type text, select, textarea
        for (let i = 0; i < requiredInputElem.length; i++) {
            const elem = requiredInputElem[i];
            if (elem.value.length === 0) {
                this.formHasInputErrors = true;
                break;
            } else {
                this.formHasInputErrors = false;
            }
        }

        // check errors for input type email
        for (let i = 0; i < requiredEmailElem.length; i++) {
            const elem = requiredEmailElem[i];
            if (elem.value.length === 0) {
                this.formHasEmailErrors = true;
                break;
            } else {
                if (!this.emailRegex.test(elem.value)) {
                    this.formHasEmailErrors = true;
                    break;
                } else {
                    this.formHasEmailErrors = false;
                }
            }
        }

        // check errors for checkboxes fields
        for (let i = 0; i < requiredCheckboxFields.length; i++) {
            const field = $(requiredCheckboxFields[i]);
            if (field.find('input[type=checkbox]:checked').length === 0) {
                this.formHasCheckboxErrors = true;
                break;
            } else {
                this.formHasCheckboxErrors = false;
            }
        }

        requiredInputElem.each(function () {
            that.fieldValidation(this);
        });

        requiredEmailElem.each(function () {
            that.fieldValidation(this, true);
        });

        requiredCheckboxFields.each(function () {
            that.fieldValidation(this, false, true);
        });
    }

    onFormSubmit() {
        let that = this;
        const submitButton = this.$element.find('.EditingFormButtonCell .FormButton');

        submitButton.on('click', function (e) {
            that.checkIfFormHasErrors();
            if (that.formHasInputErrors || that.formHasEmailErrors || that.formHasCheckboxErrors) {
                e.preventDefault();
            } else {
                // prevents multiclick on submit button
                const disableButtonOverlay = $(`<div class="FormButton__disable-overlay"></div>`);
                if ($(this).hasClass('FormButton--clicked')) {
                    return;
                }
                $(this).addClass('FormButton--clicked');
                $(this).parent().prepend(disableButtonOverlay);
            }
        });
    }

    fixFormUploader() {
        let singleUploadElem = $('.uploader-input-file');
        let uploadElem = $('input[multiple="multiple"]');
        let uploadParent = uploadElem.parents('tr, .tr');
        const noFileText = window.localizationStrings ? window.localizationStrings.uploadButton.noFileChosenText : 'No file uploaded';
        if (uploadElem && uploadParent) {
            uploadParent.addClass('tr-upload');
            uploadParent.find('.FieldLabel').addClass('FieldLabel--upload');

            singleUploadElem.each(function () {
                const self = $(this);
                let uploadParent = self.parents('tr, .tr');
                uploadParent.addClass('tr-upload');
                uploadParent.find('.FieldLabel').addClass('FieldLabel--upload');
                const konicaUploader = '.form__uploader';
                const konicaUploaderElement = $(`
                    <div class="form__uploader">
                        <label class="form__uploader-label" for=${self.attr('id')}>
                            ${window.localizationStrings ? window.localizationStrings.uploadButton.text : 'UPLOAD'}
                        </label>
                        <p class="form__uploader-filename">
                            ${noFileText}
                        </p>
                    </div>
                `);
                if (!self.siblings(konicaUploader).length) {
                    self.parent().prepend(konicaUploaderElement);
                }

                self.on('change', function () {
                    const fileName = self.val().length ? self.val().split('\\').pop() : noFileText;
                    const fileNameClass = '.form__uploader-filename';
                    self.prev().find(fileNameClass).text(fileName);
                });
            });
        }
    }

    // check if field is required
    checkRequiredFields() {
        this.formFieldLabelElem.each(function () {
            if ($(this).has('.required-mark').length > 0 || $(this).find('.required-mark').length > 0) {
                if ($(this).parents('.tr, tr').find('input[type=checkbox]').length > 0) {
                    $(this).parents('.tr, tr').addClass('requiredCheckbox');
                } else {
                    $(this).parents('.tr, tr').addClass('required');
                }
            }
        });
    }

    checkHiddenFieldValidation() {
        let hiddenElem = this.$element.find(this.validationParentSelector);
        hiddenElem.each(function () {
            let idName = $(this).attr('id');
            // this gets the id of the field that needs to be validated
            let fieldName = idName.split('ContactValidation')[0];
            if (fieldName) {
                $(this).closest('.FormPanel').find('#' + fieldName).addClass('wait');
            }
        });
    }

    checkBoxValidation() {
        let self = this;
        let waitingElem = this.$element.find('.wait');

        waitingElem.each(function () {
            let $waitingElem = $(this);
            self.inputCheckbox.each(function () {
                let $checkBoxElem = $(this);
                let waitingElemId = $waitingElem.attr('id').split('_').join('');
                if (waitingElemId !== undefined && $checkBoxElem.val().toLowerCase().startsWith(waitingElemId.toLowerCase())) {
                    if ($checkBoxElem.attr('checked')) {
                        $waitingElem.addClass('required');
                        self.fieldValidation($waitingElem.find('input')[0]);
                        self.realTimeValidation();
                    } else {
                        $waitingElem.removeClass('required');
                    }
                }
            });
        });
    }

    realTimeValidation() {
        // find all required fields
        let self = this;
        let requiredElem = self.$element.find('.required');
        let requiredInputElem = requiredElem.find('input[type=text]:not([id*="Email"], .CaptchaTextBox), select, textarea');
        let requiredEmailElem = requiredElem.find('input[id*=Email]');

        /* for each inputs */
        requiredInputElem.each(function () {
            $(this).focusout((e) => {
                self.fieldValidation(e.currentTarget);
            });
        });

        requiredEmailElem.each(function () {
            $(this).focusout((e) => {
                self.fieldValidation(e.currentTarget, true);
            });
        });
    }

    fieldValidation(elem, isEmail, isCheckbox) {
        let elemType;
        if (isCheckbox) {
            const field = $(elem);
            if (field.find('input[type=checkbox]:checked').length === 0) {
                let validationErrorText = this.getValidationMessages('select');
                if (field.find('.EditingFormErrorLabelCheckbox').length) {
                    field.find('.EditingFormErrorLabelCheckbox').text(validationErrorText);
                } else {
                    const errorHtml = `<span class="EditingFormErrorLabelCheckbox">${validationErrorText}</span>`;
                    field.find('.EditingFormValueCell .EditingFormControlNestedControl').append(errorHtml);
                }
            } else {
                field.find('.EditingFormErrorLabelCheckbox').remove();
            }
        } else {
            if (elem && elem.value === "") {
                /* get the current target element type and its validation message */
                if (!isEmail) {
                    elemType = $(elem).attr('type') || ($(elem).is('textarea') ? 'textarea' : $(elem).is('select') ? 'select' : 'text');
                } else {
                    elemType = 'email';
                }
                let validationErrorText = this.getValidationMessages(elemType);
                this.createValidationElem(elem, validationErrorText);
            } else if (isEmail) {
                if (this.emailRegex.test(elem.value)) {
                    this.removeValidationErrors(elem);
                } else {
                    let validationErrorText = this.getValidationMessages('email', true);
                    this.createValidationElem(elem, validationErrorText);
                }
            } else {
                this.removeValidationErrors(elem);
            }
        }
        KenticoFormValidationErrors.checkValidationPosition();
    }

    getValidationMessages(elemType, emailInCorrectFormat) {
        let validationMessages = window.validationStrings;
        if (Object) {
            // if (Object.keys(validationMessages).length > 0) {
            for (let item in validationMessages) {
                if (item === elemType) {
                    let property = validationMessages[item];
                    let errorMessage = property.defaultError;
                    // if has translation for errorMessage
                    if (property.errorMessage !== "" && property.errorMessage.indexOf('dd.forms') === -1) {
                        errorMessage = property.errorMessage;
                    }
                    // if has translation for correctFormat error 
                    if (emailInCorrectFormat && property.correctFormat !== "" && property.correctFormat.indexOf('dd.forms') === -1) {
                        errorMessage = property.correctFormat;
                    }
                    return errorMessage;
                }
            }
        } else {
            /* if everything failes */
            return "Please enter a value";
        }
    }

    createValidationElem(elem, validationErrorText) {
        /* then check if the Span Elem already exist */
        let closestElemHasValidationElem = $(elem).closest(".EditingFormValueCell").children('.EditingFormErrorLabel');

        if (closestElemHasValidationElem.length === 0) {
            /* create span elem in memory */
            let spanError = document.createElement('span');
            spanError.setAttribute('class', 'EditingFormErrorLabel');

            /* find the placement of the new span elem */
            let closestElem = $(elem).closest('.EditingFormValueCell');
            $(closestElem).append(spanError);
            $(spanError).append(validationErrorText);
        } else if (closestElemHasValidationElem.length !== 0 && elem.id.indexOf('Email') !== '1') {
            // check if already exists and if it's email email dooh, then change the existing text
            $($(elem).closest('.EditingFormValueCell')).find('.EditingFormErrorLabel').text(validationErrorText);
        }

        if (!$(elem).parent().hasClass('Error')) {
            $(elem).parent().removeClass('valid').addClass('Error');
        }
    }

    removeValidationErrors(elem) {
        $(elem).closest('.Error').removeClass('Error');
        $(elem).parents('.EditingFormValueCell').children('.EditingFormErrorLabel').remove();

        // put something green for valid
        if (!$(elem).parent().hasClass('Error')) {
            $(elem).parent().removeClass('Error').addClass('valid');
        }
    }

    hideCaptchaLabel() {
        /* yaay kentico */
        let captchaElem = this.$element.find('.CaptchaTable');
        if (captchaElem) {
            captchaElem.parents('.tr, tr').addClass('tr-captcha');
            captchaElem.parents('.tr, tr').find('.FieldLabel').addClass('FieldLabel--captcha');
        }
        let captchaLabel = this.$element.find('[id*=lblSecurityCode]');
        if (captchaLabel) {
            captchaLabel.hide();
        }
    }
}

export default KenticoForm;
