const RethinkMenu = function () {
    const menuToggle = $(".nav-rethink__hamburger");
    const rethinkMenu = $(".rethink-menu");
    const sidebar = ".rethink-menu__sidebar";
    const sidebarHiddenClass = "rethink-menu__sidebar--hidden";
    const rethinkMenuOpen = "rethink-menu--open";
    const rethinkMenuContent = $(".rethink-menu__content");
    const rethinkMenuContentOpenClass = "rethink-menu__content--open";
    const rethinkMenuSidebarContent = $(".rethink-menu__sidebar-list-content");
    const rethinkMenuSidebarContentOpenClass =
        "rethink-menu__sidebar-list-content--open";
    const disableScrolling = "disable-scrolling";
    const $rethinkListLinksContainer = $(
        ".rethink-menu__sidebar-list-content-footer"
    );
    const rethinkListLinksContainer =
        ".rethink-menu__sidebar-list-content-footer";
    const rethinkMenuLinks = $(
        ".rethink-menu__sidebar-list li.js-rethink-menu__load-lvl2"
    );
    const rethinkMenuLinksActiveClass = "active";
    const closeMenuContent = $(
        ".rethink-menu__sidebar-top-close, .rethink-menu__content-top-close"
    );
    const backMenuContent = $(".rethink-menu__content-top-back");
    const isDesktop = window.matchMedia("(min-width: 661px)").matches;

    rethinkMenuSidebarContent.each(function (idx) {
        const self = $(this);
        self.attr("data-id", idx);
    });
    rethinkMenuLinks.each(function (idx) {
        const self = $(this);
        self.attr("data-id", idx);
    });

    function closeMenu() {
        const isMobile =
            $(".rethink-menu__sidebar-top").css("position") === "absolute";
        if (isMobile) {
            if (rethinkMenuContent.hasClass(rethinkMenuContentOpenClass)) {
                if ($(".header-rethink").hasClass("header-rethink--left")) {
                    rethinkMenuContent.addClass(
                        "rethink-menu__content--closing"
                    );
                    setTimeout(function () {
                        rethinkMenuContent.removeClass(
                            "rethink-menu__content--closing"
                        );
                        $(sidebar).removeClass(sidebarHiddenClass);
                        rethinkMenuLinks.removeClass(
                            rethinkMenuLinksActiveClass
                        );
                        rethinkMenuContent.removeClass(
                            rethinkMenuContentOpenClass
                        );
                        rethinkMenu.removeClass("rethink-menu--content-open");
                        rethinkMenuSidebarContent.fadeOut(500);
                        rethinkMenuLinks.removeClass(
                            rethinkMenuLinksActiveClass
                        );
                        rethinkMenuContent.css("display", "none");
                        rethinkMenu.removeClass(rethinkMenuOpen);
                    }, 500);
                    setTimeout(function () {
                        rethinkMenu.css("display", "none");
                        $("body").removeClass(disableScrolling);
                    }, 1000);
                } else {
                    rethinkMenuContent.addClass(
                        "rethink-menu__content--closing-right"
                    );
                    $(sidebar).css("display", "none");
                    setTimeout(function () {
                        rethinkMenuContent.removeClass(
                            "rethink-menu__content--closing-right"
                        );
                        $(sidebar).css("display", "block");
                        rethinkMenu.removeClass(rethinkMenuOpen);
                    }, 500);
                    setTimeout(function () {
                        rethinkMenu.css("display", "none");
                        $(sidebar).removeClass(sidebarHiddenClass);
                        rethinkMenuLinks.removeClass(
                            rethinkMenuLinksActiveClass
                        );
                        rethinkMenuContent.removeClass(
                            rethinkMenuContentOpenClass
                        );
                        rethinkMenu.removeClass("rethink-menu--content-open");
                        rethinkMenuSidebarContent.fadeOut(500);
                        rethinkMenuLinks.removeClass(
                            rethinkMenuLinksActiveClass
                        );
                        rethinkMenuContent.css("display", "none");
                        $("body").removeClass(disableScrolling);
                    }, 1000);
                }
            } else {
                rethinkMenu.removeClass(rethinkMenuOpen);
                setTimeout(function () {
                    rethinkMenuContent.css("display", "none");
                    rethinkMenu.css("display", "none");
                    $("body").removeClass(disableScrolling);
                }, 500);
            }
        } else {
            if (rethinkMenuContent.hasClass(rethinkMenuContentOpenClass)) {
                $(sidebar).removeClass(sidebarHiddenClass);
                rethinkMenuLinks.removeClass(rethinkMenuLinksActiveClass);
                rethinkMenuContent.removeClass(rethinkMenuContentOpenClass);
                rethinkMenu.removeClass("rethink-menu--content-open");
                rethinkMenuSidebarContent.fadeOut(500);
                rethinkMenuLinks.removeClass(rethinkMenuLinksActiveClass);
                setTimeout(function () {
                    rethinkMenuContent.css("display", "none");
                    rethinkMenu.removeClass(rethinkMenuOpen);
                }, 500);
                setTimeout(function () {
                    rethinkMenu.css("display", "none");
                    $("body").removeClass(disableScrolling);
                }, 1000);
            } else {
                rethinkMenu.removeClass(rethinkMenuOpen);
                setTimeout(function () {
                    rethinkMenuContent.css("display", "none");
                    rethinkMenu.css("display", "none");
                    $("body").removeClass(disableScrolling);
                }, 500);
            }
        }
    }

    //Hamburger onClicl
    menuToggle.on("click", function () {
        if (rethinkMenu.hasClass(rethinkMenuOpen)) {
            closeMenu();
        } else {
            rethinkMenu.css("display", "block");
            setTimeout(function () {
                rethinkMenu.addClass(rethinkMenuOpen);
                $("body").addClass(disableScrolling);
            }, 10);
        }
    });

    //lvl1 menu item onClick
    rethinkMenuLinks.on("click", function () {
        const self = $(this);
        const linkId = self.attr("data-id");
        rethinkMenuContent.css("display", "block");
        setTimeout(function () {
            rethinkMenuLinks.removeClass(rethinkMenuLinksActiveClass);
            rethinkMenuSidebarContent.removeClass(
                rethinkMenuSidebarContentOpenClass
            );
            self.addClass(rethinkMenuLinksActiveClass);
            rethinkMenuContent.addClass(rethinkMenuContentOpenClass);
            rethinkMenu.addClass("rethink-menu--content-open");
            self.parents(sidebar).addClass(sidebarHiddenClass);
        }, 10);
        setTimeout(function () {
            $(`.rethink-menu__sidebar-list-content[data-id=${linkId}]`).fadeIn(
                500
            );
        }, 500);
        $(`.rethink-menu__sidebar-list-content[data-id=${linkId}]`)
            .siblings(".rethink-menu__sidebar-list-content")
            .fadeOut(500);
    });

    closeMenuContent.on("click", function () {
        closeMenu();
    });

    backMenuContent.on("click", function () {
        rethinkMenuLinks.removeClass(rethinkMenuLinksActiveClass);
        $(sidebar).removeClass(sidebarHiddenClass);
        rethinkMenuContent.removeClass(rethinkMenuContentOpenClass);
        rethinkMenu.removeClass("rethink-menu--content-open");
        rethinkMenuSidebarContent.addClass(rethinkMenuSidebarContentOpenClass);
    });

    // Detects if menu footer links are breaking out of container. In that case, link blocks are handled with "masonry" like behaviour
    /* if (isDesktop) {
        const containerRightPos = $rethinkListLinksContainer[0].getBoundingClientRect().right;
        $rethinkListLinksContainer.each(function () {
            const self = $(this);
            const rethinkListLinks = self.find('.rethink-menu__sidebar-list-content-footer-block');

            rethinkListLinks.each(function (idx) {
                const self = $(this);
                const selfRightPosition = self[0].getBoundingClientRect().right;
                if (idx > 4) {
                    self.parents(rethinkListLinksContainer).addClass('rethink-menu__sidebar-list-content-footer--column');
                } else {
                    self.parents(rethinkListLinksContainer).removeClass('rethink-menu__sidebar-list-content-footer--column');
                }
                if (selfRightPosition > containerRightPos) {
                    console.log('overflow');
                    const offsetHeight = self.height();
                    const adjustedHeight = self.parents(rethinkListLinksContainer).height() + offsetHeight;
                    self.parents(rethinkListLinksContainer).height(adjustedHeight);
                }
            });
        })
    } */
};

export default RethinkMenu;
