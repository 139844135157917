class CustomDropdown {
    dropdownElem = $('.custom-dropdown__toggle');
    mainContent = $('.main-content');
    mainContentOverflowClass = 'main-content--overflow';

    init() {
        this.toggleMainContentOverflow();
    }

    toggleMainContentOverflow() {
        this.dropdownElem.click(() => {
            this.mainContent.toggleClass(this.mainContentOverflowClass);
        });
        $(document).click(() => {
            if (this.mainContent.hasClass(this.mainContentOverflowClass)) {
                this.mainContent.removeClass(this.mainContentOverflowClass);
            }
        });
    };
}

export default CustomDropdown;
