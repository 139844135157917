class YoutubeVideoPlayer {
    playerInfoList = [];
    players = [];

    constructor(ytVideos) {
        this.ytVideos = ytVideos;
    }

    init() {
        this.initYtVideos();
    }

    initYtVideos() {
        const YOUTUBE_API_KEY = 'AIzaSyDbMHISNWweMYfPztoAFPdhfdV-SgkdnvA';

        const playerInfoList = this.playerInfoList;
        const players = this.players;

        this.ytVideos.each((i, el) => {
            const ytVideoIdEl = $(el).children('[yt-video-id]');
            const ytVideoElementId = ytVideoIdEl.attr('id');
            const ytVideoId = ytVideoIdEl.attr('yt-video-id');

            //lsostar - these are all undefined if no attribute found, so they're all 'falsey', so i can 'if' them later - https://api.jquery.com/attr/
            const ytVideoAutoStart = ytVideoIdEl.attr('yt-video-autostart');    
            const ytVideoLoop = ytVideoIdEl.attr('yt-video-loop');
            const ytVideoTimeStamp = ytVideoIdEl.attr('yt-video-timestamp');    

            const videoInfo = {
                id: ytVideoElementId,
                videoId: ytVideoId,

                //lsostar
                autostart: ytVideoAutoStart,
                loop: ytVideoLoop,
                timestamp: ytVideoTimeStamp     
            };

            playerInfoList.push(videoInfo);
        });

        window.onYouTubeIframeAPIReady = function () {
            if (typeof playerInfoList === 'undefined') {
                return;
            }

            for (let i = 0; i < playerInfoList.length; i++) {
                initYoutubeLdScript(playerInfoList[i].videoId);
                players[playerInfoList[i].id] = createPlayer(playerInfoList[i]);
            }
        };

        function createPlayer(playerInfo) {
            //lsostar -
            //  https://developers.google.com/youtube/iframe_api_reference
            //  https://developers.google.com/youtube/player_parameters

            var playerVars = {
                rel: 0,
                showinfo: 0
            };
            //autoplay
            if (playerInfo.autostart) {
                playerVars.autoplay = 1
            }
            //loop
            if (playerInfo.loop) {
                playerVars.loop = 1
            }
            //starting time in seconds
            if (playerInfo.timestamp) {
                playerVars.start = playerInfo.timestamp
            }


            //console.log("*** playerVars: ", playerVars);


            return new YT.Player(playerInfo.id, {
                videoId: playerInfo.videoId,
                host: 'http://www.youtube-nocookie.com',
                playerVars: playerVars
            });
        }

        function initYoutubeLdScript(id) {
            const url = "https://www.googleapis.com/youtube/v3/videos?part=snippet&id=" + id + "&key=" + YOUTUBE_API_KEY;
            const xhttp2 = new XMLHttpRequest();
            xhttp2.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var responseObject = JSON.parse(this.response);

                    var video = responseObject.items[0];
                    var jsonld = {
                        "@context": "http://schema.org",
                        "@type": "VideoObject",
                        "name": video.snippet.title,
                        "description": video.snippet.description,
                        "thumbnailUrl": [
                            video.snippet.thumbnails.default.url,
                            video.snippet.thumbnails.medium.url,
                            video.snippet.thumbnails.high.url
                            // , video.snippet.thumbnails.maxres1.url
                        ],
                        "uploadDate": video.snippet.publishedAt,
                        "contentUrl": "https://www.youtube.com/watch?v=" + video.id,
                        "embedUrl": "https://www.youtube.com/embed/" + video.id 
                        /*
                                ,"duration": "PT1M33S",
                                "interactionCount": "2347",
                                "publisher": {
                                  "@type": "Organization",
                                      "name": "My Company",
                                      "sameAs": "https://www.Company.com/",
                                  "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://www.Company.com/logo.svg",
                                    "width": 600,
                                    "height": 60
                                  }
                        */
                    };

                    var script = document.createElement("script");
                    script.type = "application/ld+json";
                    script.text = JSON.stringify(jsonld);
                    document.getElementsByTagName("head")[0].appendChild(script);
                }
            };
            xhttp2.open("GET", url, true);
            xhttp2.send();
        }
    }
}

export default YoutubeVideoPlayer;
