const debounce = require('debounce');

class ProductInfoTabs {

    init() {
        this.toggleLinksVisibility();
        const that = this;
        if ('addEventListener' in window) {
            const handleResize = debounce(function () {
                that.toggleLinksVisibility();
            }, 25);
            window.addEventListener('resize', handleResize);
        }
    }

    toggleLinksVisibility() {
        const tabLinksContainer = $('.product-specifications .horizontal-tabs');
        const dropdownBtn = $('.product-specifications .custom-dropdown__toggle');
        const dropdownLinks = $('.product-specifications .dropdown-menu .dropdown-item');
        const dropdownMenu = $('.product-specifications .dropdown-menu');
        const dropdown = $('.product-specifications .dropdown');
        const tabLinks = tabLinksContainer.find('.nav-item');

        if (tabLinksContainer.length) {
            const tabLinksContainerOffsetRight = $(window).width() - Math.floor(tabLinksContainer[0].getBoundingClientRect().left) - tabLinksContainer.width();
            // toggle visibility of tab links and dropdown links
            tabLinks.each(function (index) {
                const tabLink = $(this);
                const dropdownLink = $(dropdownLinks[index]);
                const tabLinkOffsetRight = $(window).width() - Math.floor(tabLink[0].getBoundingClientRect().left) - tabLink.width();
                if (tabLinkOffsetRight < tabLinksContainerOffsetRight + dropdownBtn.width()) {
                    tabLink.addClass('nav-item--hidden');
                    dropdownLink.addClass('dropdown-item--visible');
                } else {
                    tabLink.removeClass('nav-item--hidden');
                    dropdownLink.removeClass('dropdown-item--visible');
                }
            });
        }

        // toggle visibility of dropdown
        if (dropdown.length) {
            if (dropdownMenu.find('.dropdown-item--visible').length) {
                dropdown.removeClass('dropdown--hidden');
            } else {
                dropdown.addClass('dropdown--hidden');
            }
        }
    }
}

export default ProductInfoTabs;