import BrowserDetection from '../browser-detection/browser-detection'

const webpImages = () => {
    const { isSafari, version } = BrowserDetection.safariDetection();

    if (isSafari && Number(version) <= 13) {
        const img = document.querySelectorAll('img');
        img.forEach(img => {
            const attributes = img.getAttributeNames();

            if (attributes.includes('src') && attributes.includes('img-src')) {
                // image is loaded, switch img
                const src = img.getAttribute('src');
                if (src.indexOf('-FWEBP') > -1) {
                    img.setAttribute('src', src.replace('-FWEBP', '-FPNG'));
                    img.setAttribute('img-src', src.replace('-FWEBP', '-FPNG'));
                }

            } else if (attributes.includes('src')) {
                const src = img.getAttribute('src');
                if (src.indexOf('-FWEBP') > -1) {
                    img.setAttribute('src', src.replace('-FWEBP', '-FPNG'));
                }
            } else if (attributes.includes('img-src')) {
                const imgsrc = img.getAttribute('img-src');
                if (imgsrc.indexOf('-FWEBP') > -1) {
                    img.setAttribute('img-src', imgsrc.replace('-FWEBP', '-FPNG'));
                }
            } else if (attributes.includes('mobile-img-src')) {
                const imgsrc = img.getAttribute('mobile-img-src');
                if (imgsrc.indexOf('-FWEBP') > -1) {
                    img.setAttribute('mobile-img-src', imgsrc.replace('-FWEBP', '-FPNG'));
                }
            }
        });

        const div = document.querySelectorAll('div');
        div.forEach(div => {
            const imgsrc = div.getAttribute('img-src');
            if (imgsrc && imgsrc.indexOf('-FWEBP') > -1) {
                div.setAttribute('img-src', imgsrc.replace('-FWEBP', '-FPNG'));

                const computedStyles = getComputedStyle(div);
                if (computedStyles && computedStyles.backgroundImage !== 'none') {
                    const url = computedStyles.backgroundImage;
                    div.style.backgroundImage = url.replace('-FWEBP', '-FPNG');
                }
            }
        });

        const anchor = document.querySelectorAll('a');
        anchor.forEach(a => {
            const imgsrc = a.getAttribute('img-src');
            if (imgsrc && imgsrc.indexOf('-FWEBP') > -1) {
                a.setAttribute('img-src', imgsrc.replace('-FWEBP', '-FPNG'));

                const computedStyles = getComputedStyle(a);
                if (computedStyles && computedStyles.backgroundImage !== 'none') {
                    const url = computedStyles.backgroundImage;
                    a.style.backgroundImage = url.replace('-FWEBP', '-FPNG');
                }
            }
        });
    }
}

export default webpImages;