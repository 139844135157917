const lpShadow = () => {
    const lpShadowEl = document.querySelector('#lpShadow');
    const lpShadowValue = lpShadowEl ? lpShadowEl.dataset.dropshadow : null;
    const lpHeader = document.querySelector('.lp-header');

    if (!lpShadowEl || lpShadow && lpShadowValue && lpShadowValue !== "False") {
        if (lpHeader) {
            lpHeader.classList.add('lp-header--shadow');
        }
    }

    // Marko - 21.06
    // added hidden input to determine if the logo is displayed
    // need to check if the menu is shown as well
    // if no elements are to be shown, then hide the whole header
    // right now, it prevents input on the top section of the page

    
    const lpLogoEl = document.querySelector('#lpLogo');
    const lpLogoValue = lpLogoEl ? lpLogoEl.dataset.showlogo : null;
    
    const hasListItems = document.querySelector('ul.lp-header__nav');

    if (hasListItems === null && // no items for navigation
        (lpShadowEl && lpLogoEl) // make sure that the hidden inputs are actually there...
        && (lpShadowValue === "False" && lpLogoValue === "False")) // both inputs have the value false
        {
        // hide the header if present
        if(lpHeader){
            lpHeader.classList.add('hidden');
        }
    }
};

export default lpShadow;