import General from '../general/general';

class EventSpeakersSwiper {
    general = new General;
    eventSpeakersSliderOptions = {
        observer: true,
        observeParents: true,
        pagination: {
            el: '.event-speakers__swiper-pagination',
            bulletClass: 'swiper-bullet',
            bulletActiveClass: 'swiper-bullet--active',
        },
        slidesPerView: 3,
        breakpoints: {
            768: {
                slidesPerView: 2
            },
            660: {
                slidesPerView: 'auto'
            }
        }
    };

    init() {
        this.swiperInit();
    }

    swiperInit(el) {
        const nextEl = el.next('.swiper-nav-buttons');
        this.eventSpeakersSliderOptions.navigation = {
            nextEl: nextEl.find('.swiper-button-next'),
            prevEl: nextEl.find('.swiper-button-prev')
        };
        this.general.initSwiper(el, this.eventSpeakersSliderOptions);
    }
}

export default EventSpeakersSwiper;
