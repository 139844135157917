class CompareLane {
    init() {
        //console.log("COMPARE INIT()");
        this.toggleComparison();
    }

    toggleComparison() {
        const compareBtns = $('.js-add-to-comparison');
        const compareLane = $('.js-compare-lane');
        const compareLaneList = compareLane.children('ul');
        const compareNowButtons = $('.js-compare-btn');

        function getNumberOfItemsInComparisonLane() {
            const items = $('.js-compare-lane ul li');

            return items.length;
        }

        function updateComparisonPageUrl(urlProductCodename, shouldRemove) {
            const compareBasicURL = $('.js-compare-url')[0].value;
            const currentUrl = compareNowButtons.attr('href') === "#" ? compareBasicURL : compareNowButtons.attr('href');
            const numberOfComparableItems = getNumberOfItemsInComparisonLane();
            let commaOrEmptySeparator;

            if (shouldRemove) {
                let reducedUrl;
                let stringToReplace;

                if (currentUrl.indexOf(urlProductCodename + ',') > -1) {
                    stringToReplace = urlProductCodename + ',';
                } else if (currentUrl.indexOf(',' + urlProductCodename + ',') > -1) {
                    stringToReplace = ',' + urlProductCodename + ',';
                } else if (currentUrl.indexOf(',' + urlProductCodename) > -1) {
                    stringToReplace = ',' + urlProductCodename;
                } else {
                    stringToReplace = urlProductCodename;
                }

                reducedUrl = currentUrl.replace(stringToReplace, '');
                compareNowButtons.attr('href', reducedUrl);
            } else {
                commaOrEmptySeparator = numberOfComparableItems > 1 ? ',' : '';
                const updatedUrl = currentUrl + commaOrEmptySeparator + urlProductCodename;

                compareNowButtons.attr('href', updatedUrl);
            }
        }

        function toggleCompareNowButtons() {
            const numberOfComparableItems = getNumberOfItemsInComparisonLane();

            if (numberOfComparableItems > 1) {
                compareNowButtons.removeClass('compare-btn--hidden');
            } else {
                compareNowButtons.addClass('compare-btn--hidden');
            }
        }

        function removeFromComparison(elementToRemove) {
            elementToRemove.remove();

            const compareLaneListItems = compareLaneList.children();

            if (compareLaneListItems.length === 0) {
                compareLane.addClass('compare-lane--hidden');
            }
        }

        compareBtns.on('click', function (e) {
            const self = $(this);

            const productCodename = self.attr('data-codename');
            const productName = self.attr('data-product-name');
            const productIcon = self.attr('data-icon-image');
            const compareLaneListItems = compareLaneList.children();

            e.preventDefault();

            //console.log("**CLICK event")

            if (self.hasClass('js-item-in-comparison')) {
                //console.log("***click remove")
                const compareLaneEl = compareLaneList.children('[data-codename=' + productCodename + ']');

                updateComparisonPageUrl(productCodename, true);

                removeFromComparison(compareLaneEl);

                toggleCompareNowButtons();

                self.removeClass('link-with-check-icon--active js-item-in-comparison')
            } else if (compareLaneListItems.length < 3) {
                //console.log("***click add")
                compareLaneList.append('<li data-codename="' + productCodename + '">\n' +
                    '            <span class="js-remove-from-comparison"></span>\n' +
                    '            <img src="' + productIcon + '" alt="' + productName + '"/>\n' +
                    '            <span>' + productName + '</span>\n' +
                    '        </li>');

                toggleCompareNowButtons();

                updateComparisonPageUrl(productCodename);

                self.addClass('link-with-check-icon--active js-item-in-comparison');

                compareLane.removeClass('compare-lane--hidden');
            }
        });

        $(document).on('click', '.js-remove-from-comparison', function () {
            const self = $(this);
            const productBox = self.parent();
            const productCodename = productBox.attr('data-codename');
            const productGridEl = $('.js-add-to-comparison[data-codename=' + productCodename + ']');

            productGridEl.removeClass('link-with-check-icon--active js-item-in-comparison');

            updateComparisonPageUrl(productCodename, true);

            removeFromComparison(productBox);

            toggleCompareNowButtons();
        });
    }
}

export default CompareLane;
