import './jquery.matchHeight-min';

class ResizeText {
	
	init(){
		$.fn.resizeText = function (options) {
			'use strict';
		
			options = $.extend({}, {
				windowMinWidth: 0,
				windowMinHeight: 0,
				windowMaxWidth: 0,
				windowMaxHeight: 0
			}, options);
		
			$.resizeText.cache = [];
		
			this.each(function (i, o) {
				var $this = $(o);
				$this.addClass('rz');
				$.resizeText.cache.push({
					$el: $this,
					options: options
				});
			});
		
			$.resizeText.init();
		};
		
		$.resizeText = {
			init: function () {
				'use strict';
				$.resizeText.resize();
				if (!$.resizeText.event) {
					$.resizeText.event = true;
					$(window).resize(function () {
						if ($.resizeText.interval) {
							clearTimeout($.resizeText.interval);
						}
						$.resizeText.interval = setTimeout(function () {
							$.resizeText.resize();
						}, 30);
					});
				}
			},
		
			resize: function () {
				'use strict';
				$($.resizeText.cache).each(function (i, o) {
					if (
						(o.options.windowMinWidth !== 0 && o.options.windowMinWidth > window.innerWidth) ||
						(o.options.windowMinHeight !== 0 && o.options.windowMinHeight > window.innerHeight) ||
						(o.options.windowMaxWidth !== 0 && o.options.windowMaxWidth < window.innerWidth) ||
						(o.options.windowMaxHeight !== 0 && o.options.windowMaxHeight < window.innerHeight)
					) {
						o.$el.css('font-size', ''); // reset
						return;
					}
		
					o.$el.css('font-size', '0.1em'); // reset
					var width = o.$el.parent().width(),
						height = o.$el.innerHeight();
		
					$.resizeText.increaseSize(1, 0.1, o.$el, width, height);
				});
				if (typeof $.fn.matchHeight._update === 'function') {
					$.fn.matchHeight._update();
				}
			},
		
			increaseSize: function (increment, start, newElem, width, height) {
				'use strict';
		
				var fontSize = start;
				newElem.css('font-size', '0.1em'); // reset
				while (newElem.outerWidth() <= width && fontSize < 6) {
					fontSize += increment;
					newElem.css('font-size', fontSize + 'em');
				}
		
				if (newElem.outerWidth() > width || newElem.outerHeight() > height) {
					fontSize -= increment;
					newElem.css('font-size', fontSize + 'em');
		
					if (increment > 0.1) {
						$.resizeText.increaseSize(increment / 10, fontSize, newElem, width, height);
					}
				}
			}
		};		
	}
}

export default ResizeText;