const newsDetailFixImgMargin = function () {
    const images = $('.js-fix-img-margin img');

    images.each(function () {
        const img = $(this)[0];
        if (img.style.cssFloat === "right") {
            $(img).addClass('margin-img-right');
        } else if (img.style.cssFloat === "left") {
            $(img).addClass('margin-img-left');
        } else {
            $(img).addClass('margin-img');
        }
    });
};

export default newsDetailFixImgMargin;