class LanguagePopup {

    init() {
        this.onLanguageClick();
        this.onPageLoad();
    }

    onPageLoad() {
        if ($('.language-pop-up').length) {
            const cookies = document.cookie.split(';');
            const preferredCultureCookie = cookies.find(cookie => cookie.indexOf('preferredCulture') > -1);
            const languageSessionCookie = cookies.find(cookie => cookie.indexOf('languageSessionCookie') > -1);
            const userSelectedCultureCookie = cookies.find(cookie => cookie.indexOf('userSelectedCulture') > -1);
            if (userSelectedCultureCookie) {
                document.cookie = 'userSelectedCulture=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            } else {
                if (preferredCultureCookie) {
                    const preferredCulture = preferredCultureCookie.split('=')[1];
                    if (preferredCulture === 'none') {
                        document.cookie = 'preferredCulture=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    }
                    const siteCulture = window.location.pathname.replace(/\//g, "");
                    if (siteCulture.toLowerCase() !== preferredCulture.toLowerCase() && preferredCulture !== 'none') {
                        const redirectUrlToPreferredCulture = `${window.location.origin}/${preferredCulture}`;
                        window.location.href = redirectUrlToPreferredCulture;
                    }
                }
                if (!preferredCultureCookie && !languageSessionCookie) {
                    $('.language-pop-up').addClass('popup--open');
                }
            }
        }
    }

    onLanguageClick() {
        const $languageButton = $('.js-language-redirect');
        const $rememberLanguageCheckbox = $('.js-remember-language');
        $languageButton.on('click', function(e) {
            document.cookie = 'languageSessionCookie=isInSession';
            e.preventDefault();
            const language = $(this).attr('data-language');
            const shouldRememberLanguage = $rememberLanguageCheckbox.is(":checked");
            if (shouldRememberLanguage) {
                let now = new Date();
                now.setFullYear(now.getFullYear() + 1);
                document.cookie = `preferredCulture=${language}; expires= ${now.toUTCString()}; path=/`;
            } else {
                document.cookie = `preferredCulture=none`;
            }
            window.location.href = $(this).attr('href');
        });
    }
}

export default LanguagePopup;
