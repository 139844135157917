class Header {
    searchInputHeader = $('.search--header .searchBox input[type="submit"]');
    searchInputTextHeader = $('.search--header .searchBox input[type="text"]');
    searchInputOpenClass = 'searchBox--open';
    formSubmitted = false;

    init() {
        this.onSearchInputHeaderClick();
        this.onFormHeaderSubmit();
        this.onLanguageDropdownClick();
        this.onLanguageOptionClick();
    }

    onFormHeaderSubmit() {
        this.searchInputTextHeader.keyup((e) => {
            const self = $(e.currentTarget);
            if (e.keyCode === 13) {
                this.formSubmitted = true;
                self.siblings('input[type="submit"]').click();
            }
        })
    }

    onSearchInputHeaderClick() {
        this.searchInputHeader.click((e) => {
            const self = $(e.currentTarget);
            self.prev()[0].focus();
            if (!this.formSubmitted) {
                e.preventDefault(); /* Stop form submit */
                self.parent().toggleClass(this.searchInputOpenClass);
            }
            this.formSubmitted = false;
        });
    }

    onLanguageOptionClick() {
        if ($('.language-pop-up').length) {
            const $language = $('.nav-rethink__language-dropdown a, .rethink-menu__language-dropdown a');
            $language.on('click', function (e) {
                e.preventDefault();
                document.cookie = 'userSelectedCulture=true';
                window.location.href = $(this).attr('href');
            });
        }
    }

    onLanguageDropdownClick() {
        const $header = $('.header-rethink');
        const $openDropdownLink = $('.js-open-language-dropdown');

        $openDropdownLink.on('click', function () {
            const $headerLanguageDropdown = $(this).parents('.js-language').find('.js-language-dropdown');
            if (!$header.hasClass('header-rethink--dropdown-is-open')) {
                $header.addClass('header-rethink--dropdown-is-open');
                $(this).parent().addClass('active');
                $(this).parent().siblings().removeClass('active');
                $headerLanguageDropdown.show();
            } else {
                $header.removeClass('header-rethink--dropdown-is-open');
                $(this).parent().removeClass('active');
                $headerLanguageDropdown.hide();
            }
        });

        const $openCountryDropdownLink = $('.js-open-country-dropdown');
        $openCountryDropdownLink.on('click', function () {
            const $headerCountryDropdown = $(this).parents('.js-country').find('.js-country-dropdown');
            if (!$header.hasClass('header-rethink--dropdown-is-open')) {
                $header.addClass('header-rethink--dropdown-is-open');
                $(this).parent().addClass('active');
                $(this).parent().siblings().removeClass('active');
                $headerCountryDropdown.show();
            } else {
                $header.removeClass('header-rethink--dropdown-is-open');
                $(this).parent().removeClass('active');
                $headerCountryDropdown.hide();
            }
        });

        $('body').on('click', function (e) {
            const target = $(e.target);
            const isOpenDropdownLink = target.hasClass('js-open-language-dropdown') || target.children('.js-open-language-dropdown').length !== 0;
            const isDropdown = target.hasClass('js-language-dropdown') || target.parents('.js-language-dropdown').length !== 0;
            const isCountryDropdownLink = target.hasClass('js-open-country-dropdown') || target.children('.js-open-country-dropdown').length !== 0;
            const isCountryDropdown = target.hasClass('js-country-dropdown') || target.parents('.js-country-dropdown').length !== 0;
            if (!isOpenDropdownLink && !isDropdown) {
                $openDropdownLink.parents('.js-language').find('.js-language-dropdown').hide();
                $openDropdownLink.parent().removeClass('active');
                $header.removeClass('header-rethink--dropdown-is-open');
            }
            if (!isCountryDropdownLink && !isCountryDropdown) {
                $openCountryDropdownLink.parents('.js-country').find('.js-country-dropdown').hide();
                $openCountryDropdownLink.parent().removeClass('active');
                $header.removeClass('header-rethink--dropdown-is-open');
            }
            if ($('.nav-rethink').find('.searchBox--open').length) {
                if (!target.is('.searchBox input[type=text]') && !target.is('.search--header .searchBox input[type="submit"]')) {
                    $('.nav-rethink').find('.searchBox--open').removeClass('searchBox--open');
                }
            }
        });
    }
}

export default Header;
