class HomeIntroMediaSwap {
    init() {
        this.onClick();
    }

    onClick() {
        let count = 0;
        const screenXsMax = window.matchMedia('(min-width: 767px)');

        $(document).ready(function () {
            const firstImage = $('.bg-image__helper').parent().find('*[img-src]').attr('img-src');
            HomeIntroMediaSwap.swapBackgroundImage('.bg-image__helper', firstImage);
            if ($('.bg-image--home-intro .bg-video-wrapper') && screenXsMax.matches) {
                $('.bg-video-wrapper').addClass('bg-video--active-bg');
                $('.bg-video-wrapper').find('.bg-video').addClass('bg-video--is-visible');
            }
        })

        $('.js-intro-link').on('click', function () {
            const imageUrl = $(this).attr('data-imageurl');
            const videoUrl = $(this).attr('data-videourl');
            count++;

            if (imageUrl) {
                if (count % 2 === 0) {
                    HomeIntroMediaSwap.swapBackgroundImage('.bg-image--home-intro', imageUrl);
                    $('.bg-image__helper').removeClass('bg-image--active-bg');
                } else {
                    HomeIntroMediaSwap.swapBackgroundImage('.bg-image__helper', imageUrl);
                    $('.bg-image__helper').addClass('bg-image--active-bg');
                }
            }

            if (videoUrl) {
                HomeIntroMediaSwap.swapBackgroundVideo('.bg-video__source', videoUrl);

                $('.bg-video-wrapper .bg-video').clone().addClass('bg-video--is-visible').prependTo('.bg-video-wrapper');
                $('.bg-video-wrapper .bg-video').slice(1).removeClass('bg-video--is-visible');
                $(this).parents('.home-intro').prev('.bg-video-wrapper').find('.bg-video--is-visible').removeAttr('preload').attr('autoplay', 'autoplay');
                
                if (!screenXsMax.matches) {
                    HomeIntroMediaSwap.swapBackgroundVideo('.bg-video__source', videoUrl);
                    $(this).parents('.home-intro').prev('.bg-video-wrapper').find('.bg-video--is-visible').removeAttr('preload').attr('autoplay', 'autoplay');
                    $(this).parents('.home-intro').prev('.bg-video-wrapper').find('.bg-video--is-visible').trigger('pause');
               }
            setTimeout(function () {
                    $('.bg-video-wrapper .bg-video').slice(1).remove();
                }, 500);
                $('.bg-video').parent().addClass('bg-video--active-bg');
                $('.bg-image__helper').removeClass('bg-image--active-bg');
            }
        })
    }

    static swapBackgroundImage(className, newUrl) {
        if (className === "") {
            return;
        }
        const reg = /url\(.*\)/gi;
        const element = document.querySelector(className);
        if(!element) return;
        const strFooter = element.style.background;
        const newString = "url('" + newUrl + "')";
        if(reg.test(strFooter)) {
            element.style.background = strFooter.replace(reg, newString);
        } else {
            element.style.background = strFooter + " " + newString;
        }
        $('.bg-image__helper').removeClass('bg-image--active-bg');
        HomeIntroMediaSwap.showVideoNode('.bg-video__source', false);
    }

    static swapBackgroundVideo(className, newUrl) {
        if (className === "") {
            return;
        }
        const element = document.querySelector(className);
        if(!element) return;
        element.src = newUrl;
        if (newUrl === "") {
            HomeIntroMediaSwap.showVideoNode('.bg-video__source', false);
        }
        else {
            HomeIntroMediaSwap.showVideoNode('.bg-video__source', true);
        }
    }

    static showVideoNode(className, visible) {
        if (visible) {
            $(className).parent().parent().addClass('bg-video--active-bg');
        }
        else {
            $(className).parent().parent().removeClass('bg-video--active-bg');
        }
    }
}

export default HomeIntroMediaSwap;
