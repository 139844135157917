class Counter {
    init(el) {
        const elText = el.innerHTML;
        const val = parseFloat(elText);
        if(!isNaN(val)) {
            const numberArray = elText.match(/\d/g);
            const unit = elText.slice(numberArray.length, elText.length);
            let start = 0;
            setInterval(() => {
                if (start < val) {
                    start++;
                    el.innerHTML = start + unit;
                }
            }, 60);
        }
    }
}

export default Counter;
