class ProductFilter {

    init() {
        const $productFilter = $('.product-filter');
        let $clearFilterElements = $("[data-filterid]");
        let $clearFilterButton = $(".js-clear-productfilters");

        let $filterInputs = $('.custom-form--product-filter .form-group input[type=text],.custom-form--product-filter .form-group input[type=checkbox]')
        let $searchButton = $('.js-search-button');

        if ($('.js-add-to-comparison').length > 1) {
            $('.js-compare-btn').removeClass('d-none');
            $('.js-add-to-comparison').removeClass('link-with-check-icon--disabled');
        } else {
            $('.js-compare-btn').addClass('d-none');
            $('.js-add-to-comparison').addClass('link-with-check-icon--disabled');
        }

        if ($filterInputs) {
            this.setupAutoFilter($filterInputs, $searchButton);
        }

        if ($clearFilterElements) {
            this.setClearFilterButtons($clearFilterElements);
        }
        if ($clearFilterButton) {
            this.setupClearEvent($clearFilterButton, $clearFilterElements);
        }

        if ($productFilter.length > 0) {
            /* disabling the keypress on document because it's messing up with custom filtering calls */
            $(document).on('keypress', (e) => {
                if (e.key == 'Enter') {
                    e.preventDefault();
                }
            })
        }
    }

    setupAutoFilter($filterInputs, $searchButton) {
        var self = this;
        $filterInputs.each(function () {
            let $this = $(this);
            $this.on('change keyup', self.debounce(function (e) {
                // ensure reloading of JS events for compare lane after filtering
                $('.js-compare-lane').data('loaded', 'false');

                if (e.type == 'keyup' && e.key == 'Enter') {
                    e.preventDefault();
                    return false;
                }

                $searchButton.click();

            }, 250));
        });
    }

    setClearFilterButtons($filterElements) {
        var self = this;
        $filterElements.each(function () {
            let $parent = $(this);
            $($(this).find("span")[0]).click(function () {
                let filterID = $parent.attr("data-filterid");
                let filterElement = $("#" + filterID);
                self.clearFilter(filterElement, $parent);
            });
        });
    }

    clearFilter(filterElement, $parent) {
        if (filterElement) {
            if (filterElement.is("[type=text]")) {
                filterElement.val("");
                filterElement.text("");
            } else {
                filterElement.prop("checked", false);
            }
        }

        $parent.remove();
        //remove set filters section of no elements left
        if ($("[data-filterid]").length <= 0) {
            $(".js-clear-productfilters").remove();
            $("#SetFilterDisplay").remove();
            $("#SetFilterDisplayM").remove();
        }
        // ensure reloading of JS events for compare lane after filtering
        $('.js-compare-lane').data('loaded', 'false');
        $('.js-search-button').click();
    }

    setupClearEvent($clearFilterButton, $filterElements) {
        $clearFilterButton.click(function (evt) {
            evt.preventDefault();
            $filterElements.each(function () {
                $($(this).find("span")[0]).click();
            });
            // ensure reloading of JS events for compare lane after filtering
            $('.js-compare-lane').data('loaded', 'false');
            $('.js-search-button').click();
        });
    }

    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
}

export default ProductFilter;
