import General from "../general/general";

class EventLocation {
    general = new General;

    eventLocationSliderOptions = {
        observer: true,
        observeParents: true,
        pagination: {
            el: '.event-location__info-swiper-pagination',
            bulletClass: 'swiper-bullet',
            bulletActiveClass: 'swiper-bullet--active',
        },
        slidesPerView: 1
    };

    init() {
        this.swiperInit();
    }

    swiperInit() {
        const el = $('.event-location__info-swiper-container');
        const sliders = el.find('.event-location__info-swiper-slider');
        const swiperNavButtons = $('.event-location__info-swiper .swiper-nav-buttons');

        if (sliders.length > 1) {
            const nextEl = el.next('.swiper-nav-buttons');
            this.eventLocationSliderOptions.navigation = {
                nextEl: nextEl.find('.swiper-button-next'),
                prevEl: nextEl.find('.swiper-button-prev')
            };
            this.general.initSwiper(el, this.eventLocationSliderOptions);
        } else {
            swiperNavButtons.hide();
        }
    }
}

export default EventLocation;
