import General from "../general/general";

class HomeIntroNavSwiper {
    general = new General;

    homeIntroNavSliderOptions = {
        observer: true,
        observeParents: true,
        watchOverflow: true,
        slidesPerView: 1.5
    };

    swiperInit(el) {
        this.homeIntroNavSliderOptions.navigation = {
            nextEl: el.parent().find('.swiper-button-next'),
            prevEl: el.parent().find('.swiper-button-prev')
        };
        this.general.initSwiper(el, this.homeIntroNavSliderOptions);
    };
}

export default HomeIntroNavSwiper;
