

const hubspot = () => {

    const hubFrames = document.querySelectorAll('.hs-form-iframe');
    hubFrames.forEach(hf => {
        const submitBtn = hf.contentDocument.querySelector('input[type=submit]');
        const { value } = submitBtn;
        if (submitBtn && !value.includes('>')) {
            submitBtn.value += " >";
        }
    });
};

export default hubspot;