import General from "../general/general";

const swiperHeader = function () {
    const general = new General();
    $('.swiper-header').each(function() {
        const swiperHeader = $(this);
        const swiperContainer = swiperHeader.find('.swiper-container');
        const sliderOptions = {
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            speed: 500,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: swiperHeader.hasClass('swiper-header--autoplay') ? { delay: 5000 } : false,
            loop: this.classList.contains("swiper-header--loop") ? true : false,
            on: {
				paginationRender: function () {
                    if (swiperHeader.hasClass('swiper-header--autoplay') && swiperHeader.find('.swiper-slide').length > 1) {
                        const paginationWidth = swiperHeader.find('.swiper-pagination').innerWidth();
                        const leftOffset = $(window).innerWidth() / 2 - paginationWidth / 2 - 30;
                        swiperHeader.find('.swiper-header__play, .swiper-header__pause').css('left', `${leftOffset}px`);
                        swiperHeader.find('.swiper-header__pause').show();
                    }
                },
                resize: function () {
                    if (swiperHeader.hasClass('swiper-header--autoplay') && swiperHeader.find('.swiper-slide').length > 1) {
                        const paginationWidth = swiperHeader.find('.swiper-pagination').innerWidth();
                        const leftOffset = $(window).innerWidth() / 2 - paginationWidth / 2 - 30;
                        swiperHeader.find('.swiper-header__play, .swiper-header__pause').css('left', `${leftOffset}px`);
                    }
                }
            },
        }
        general.initSwiper(
            swiperContainer,
            sliderOptions
        );
    });

    $('.swiper-header__pause').on('click', function() {
        $(this).parents('.swiper-header').find('.swiper-container')[0].swiper.autoplay.stop();
        $(this).hide();
        $(this).parents('.swiper-header').find('.swiper-header__play').show();
    });

    $('.swiper-header__play').on('click', function() {
        $(this).parents('.swiper-header').find('.swiper-container')[0].swiper.autoplay.start();
        $(this).hide();
        $(this).parents('.swiper-header').find('.swiper-header__pause').show();
    });
}

export default swiperHeader;
