function decryptEmail(encoded) {
    var address = atob(encoded);
    window.location.href = "mailto:" + address;
  }
class EmailEncrypt {
    init() {
        window.DecryptMailto  = decryptEmail;
    }
}

export default EmailEncrypt;
