import Swiper from 'swiper/dist/js/swiper.js';

class Widget4StepPopup {
	modal = $('.widget-4-step__modal');
	swiperTabs = $('.widget-4-step__modal-steps span');
	stepLinks = $('.widget-4-step__step.js-open-popup');

	initSwiper(modal) {
		const that = this;

		new Swiper(modal.find('.swiper-container'), {
			navigation: {
				nextEl: modal.find('.swiper-navigation--next'),
				prevEl: modal.find('.swiper-navigation--prev')
			},
			spaceBetween: 80,
			on: {
				slideChange: function () {
					that.highlightActiveTab(modal.find('.widget-4-step__modal-steps span')[this.activeIndex]);
				},
				init: function () {
					that.highlightActiveTab(modal.find('.widget-4-step__modal-steps span')[this.activeIndex]);
				}
			}
		});
	}

	highlightActiveTab(activeStep) {
		$(activeStep).addClass('active');
		$(activeStep).siblings().removeClass('active');
	}

	bindClickEvents() {
		const { stepLinks, swiperTabs } = this;
		stepLinks.on('click', function () {
			const targetSwiperInstance = $(this).closest('.widget-4-step').find('.swiper-container')[0].swiper;
			const clickedStepIndex = $(this).parents('.widget-4-step__box').index();
			targetSwiperInstance.slideTo(clickedStepIndex, 0);
		});

		swiperTabs.on('click', function () {
			const targetSwiperInstance = $(this).parents('.popup').find('.swiper-container')[0].swiper;
			targetSwiperInstance.slideTo($(this).index());
		});
	}

	init() {
		const { modal } = this;
		const that = this;

		modal.each(function () {
			const self = $(this);
			that.initSwiper(self);
		});
		
		this.bindClickEvents();
	}
}

export default Widget4StepPopup;
