class KenticoFormValidationErrors {

    /* check if the validation error is positioned properly */
    static checkValidationPosition() {
        let errorFieldElem = $('.editing-form-control-nested-control.Error');
        errorFieldElem.each(function () {
            let $this = $(this);
            let siblingError = $this.siblings('.EditingFormErrorLabel');

            if (siblingError) {
                if ($this.next()[0] !== siblingError[0]) {
                    siblingError.detach().insertAfter($this);
                }

                /* add class modifier if first object is checkbox - don't ask why */
                let isCheckbox = $this.find('.checkbox');
                if (isCheckbox.length > 0) {
                    siblingError.addClass('push-left')
                }
            }
        });
    }
}

export default KenticoFormValidationErrors;
