export default class LpQuickCheck {

    constructor() {
        this.answers = [];
        this.answersText = {};
        this.qcAnswer = $('.js-qc-answers');
        this.qcAnswerInput = this.qcAnswer.children('input').length > 0 ? this.qcAnswer.children('input') : this.qcAnswer;
        this.points = 0;
        this.trafficLight = "";
    }

    init() {
        this.bindAnswerButtonsClickEvent();
        this.showActiveBox(0);
    }

    showActiveBox(index) {
        $($('.lp-quickcheck__box')[index]).addClass('lp-quickcheck__box--active').siblings().removeClass('lp-quickcheck__box--active');
    }

    bindAnswerButtonsClickEvent() {
        const that = this;
        const numberOfQuestions = $('.lp-quickcheck__box').not('.lp-quickcheck__box--form').length;
        $('.js-next-quickcheck-box').on('click', function () {
            const isLastQuestion = $(this).parents('.lp-quickcheck__box').index() === numberOfQuestions - 1;
            const answerId = parseInt($(this).data('answer-id'));
            that.answers.push(answerId);

            const nextIndex = $(this).parents('.lp-quickcheck__box').index() + 1;
            that.showActiveBox(nextIndex);
            if (isLastQuestion) {
                that.calculateAnswers();
            }

            that.pushAnswers(this);

            /* calculate points */
            let answerPoint = $(this).data('points');
            that.calculateAnswerPoints(answerPoint);

            if (isLastQuestion) {
                that.applyTrafficLightFlags(that);
            }

            if (this.classList.contains('js-qc-last')) {
                that.fillTheFormWithAnswers();
            }

        });
    }

    applyTrafficLightFlags(quickCheck) {
        for(let key in quickCheck.answersText){
            quickCheck.answersText[key].trafficLight = quickCheck.trafficLight;
        }
    }

    calculateAnswers() {
        let messageId;

        if( this.points > (this.answers.length / 2)) {
            this.trafficLight = "green";
            messageId = 1;
        } else if (this.points < (this.answers.length / 2)) {
            this.trafficLight = "red";
            messageId = 0;
        } else {
            this.trafficLight = "yellow";
            messageId = 2;
        }

        document.cookie = `quickcheckMessageId=${messageId}`;
        this.showRelevantMessage(messageId);
    }

    calculateAnswerPoints(point) {
        this.points += point;
    }

    showRelevantMessage(messageId) {
        $(`.lp-quickcheck__message[data-message-id=${messageId}]`).addClass('lp-quickcheck__message--active');
    }

    showFormWithMessage({messageId}) {
        if (messageId.length) {
            this.showActiveBox($('.lp-quickcheck__box--form').index());
            this.showRelevantMessage(messageId);
        } else {
            this.showActiveBox(0);
        }
    }

    formValidation() {
        $('.lp-quickcheck__box--form').addClass('lp-quickcheck__box--active');
    }

    pushAnswers(elem) {
        let questionTopParent = $(elem).parents('.lp-quickcheck__box');
        let question = questionTopParent.find('h3').text();
        let answer = typeof $(elem).find('.js-answer') !== "undefined" ? $(elem).find('.js-answer').text().trim() : "";
        let answerId = $(elem).find('.js-answer').data('answer');
        let userAction = $(elem).attr('data-answer-name').trim();
        let nodeID = questionTopParent.attr('data-node-id');
        let documentID = questionTopParent.attr('data-document-id');

        this.answersText[answerId] = {
            nodeID: nodeID,
            documentID: documentID,
            userAction: userAction,
            question: question,
            answerPDF: answer
        };
    }

    fillTheFormWithAnswers() {

        /**
         *  uncomment this if you want to fill the input value with only Yes or No values
         */
        //let answers = '';
        //
        // for (let ans in this.answersText) {
        //     // skip loop if the property is from prototype
        //     if (!this.answersText.hasOwnProperty(ans)) continue;
        //
        //     let obj = this.answersText[ans];
        //
        //     if (parseInt(ans) !== Object.keys(this.answersText).length) {
        //         answers += obj.userAction + ",";
        //
        //     } else {
        //         answers += obj.userAction;
        //     }
        //
        // }
        // this.qcAnswerInput[0].value = answers;

        /**
         *  this is filling the input with the complete object values
         */
        this.qcAnswerInput[0].value = JSON.stringify(this.answersText);

        /**
         * because for some reason, sometimes, this line -> this.qcAnswerInput[0].value = JSON.stringify(this.answersText); fails,
         * let's do it differently
         */

        let answerInputID = this.qcAnswerInput.attr('id')
        if (answerInputID.length > 0) {
            document.getElementById(answerInputID).value = JSON.stringify(this.answersText);
        }
    }
}
