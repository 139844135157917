import General from '../general/general';

class ProductGallerySwiper {
    general = new General;
    productInfoSliderEl = $('.product-gallery-swiper');
    nextBtn = $('.product-gallery-swiper').siblings('.swiper-nav-buttons').find('.swiper-button-next');
    prevBtn = $('.product-gallery-swiper').siblings('.swiper-nav-buttons').find('.swiper-button-prev');

    productInfoSliderContainer = '.product-gallery-swiper';
    productInfoSliderOptions = {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        navigation: {
            nextEl: this.nextBtn,
            prevEl: this.prevBtn
        },
        keyboard: {
            enabled: true
        },
    };

    init() {
        if (this.productInfoSliderEl.length > 0) {
            this.swiperInit();
        }
    }

    swiperInit() {
        this.general.initSwiper(this.productInfoSliderContainer, this.productInfoSliderOptions)
    }
}

export default ProductGallerySwiper;
