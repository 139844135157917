import Player from '@vimeo/player';
import General from '../general/general';

class VimeoVideoPlayer {
    constructor(videoWrapperId, videoDurationElement) {
        this.videoWrapperId = videoWrapperId;
        this.videoDurationElement = videoDurationElement;
        this.vimeoPlayer = new Player(this.videoWrapperId);
    }

    init() {
        this.getVideoDuration();
        this.toggleVideoPlayback();
    }

    getVideoDuration() {
        const videoDurationEl = this.videoDurationElement;
        const vimeoPlayer = this.vimeoPlayer;
        let formattedVideoDuration = 0;

        vimeoPlayer.getDuration().then((duration) => {
            formattedVideoDuration = General.convertSecondsToMinutes(duration);

            videoDurationEl.text(formattedVideoDuration);
        });
    }

    toggleVideoPlayback() {
        const vimeoPlayer = this.vimeoPlayer;
    }
}

export default VimeoVideoPlayer;
