import Swiper from 'swiper/dist/js/swiper.js';

export default class LpShoveler {
    shoveler = $('.lp-shoveler');

    initSwiper(shoveler) {
        const that = this;

        new Swiper(shoveler.find('.swiper-container'), {
            navigation: {
                nextEl: shoveler.find('.swiper-button-next'),
                prevEl: shoveler.find('.swiper-button-prev')
            },
            grabCursor: true,
            slidesPerView: 4,
            spaceBetween: 20,
            breakpoints: {
                1280: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 1
                },
            }
        });
    }

    init() {
        const { shoveler } = this;
        const that = this;

        shoveler.each(function () {
            const self = $(this);
            that.initSwiper(self);
        });
    }
}
