// Reusable functions
import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.css';

let productInfoSwiper;

class General {
    static convertSecondsToMinutes(seconds) {
        let min = parseInt(seconds / 60);
        let sec = seconds % 60;

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return min + ':' + sec;
    }

    static getParameterByName(name) {
        var url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    initSwiper(container, options) {
        const swiperOptions = options || {};

        if (container === '.product-info-swiper__container') {
            productInfoSwiper = new Swiper(container, swiperOptions);
        } else if (container === '.product-info__preview-swiper') {
            const productInfoPreviewSwiper = new Swiper(container, swiperOptions);
            const productInfoSwiperSlides = $('.product-info-swiper__slider');

            swiperOptions.on = {
                slideNextTransitionStart: function () {
                    const activeSlideIndex = productInfoPreviewSwiper.activeIndex;
                    const thumbnailActiveSlide = $(productInfoSwiper.slides[activeSlideIndex]);

                    thumbnailActiveSlide.prev().removeClass('activeSlide');
                    thumbnailActiveSlide.addClass('activeSlide');

                    productInfoSwiper.slideTo(activeSlideIndex);
                },
                slidePrevTransitionStart: function () {
                    const activeSlideIndex = productInfoPreviewSwiper.activeIndex;
                    const thumbnailActiveSlide = $(productInfoSwiper.slides[activeSlideIndex]);

                    thumbnailActiveSlide.next().removeClass('activeSlide');
                    thumbnailActiveSlide.addClass('activeSlide');

                    productInfoSwiper.slideTo(activeSlideIndex);
                },
                slidePrevTransitionStart: function () {
                    const activeSlideIndex = productInfoPreviewSwiper.activeIndex;
                    const thumbnailActiveSlide = $(productInfoSwiper.slides[activeSlideIndex]);

                    thumbnailActiveSlide.next().removeClass('activeSlide');
                    thumbnailActiveSlide.addClass('activeSlide');

                    productInfoSwiper.slideTo(activeSlideIndex);
                },
                click: function () {
                    const self = $(this);
                    const clickedSlideIndex = self.attr('slide-index');
                    const thumbnailActiveSlide = $(productInfoSwiper.slides[clickedSlideIndex]);

                    productInfoSwiperSlides.removeClass('activeSlide');
                    thumbnailActiveSlide.addClass('activeSlide');
                    productInfoSwiper.slideTo(clickedSlideIndex);
                    productInfoPreviewSwiper.slideTo(clickedSlideIndex);
                }
            }

            productInfoPreviewSwiper.swiperOptions = swiperOptions;

            productInfoPreviewSwiper.on('slideNextTransitionStart', function () {
                const activeSlideIndex = productInfoPreviewSwiper.activeIndex;
                const thumbnailActiveSlide = $(productInfoSwiper.slides[activeSlideIndex]);

                thumbnailActiveSlide.prev().removeClass('activeSlide');
                thumbnailActiveSlide.addClass('activeSlide');

                productInfoSwiper.slideTo(activeSlideIndex);
            });

            productInfoPreviewSwiper.on('slidePrevTransitionStart', function () {
                const activeSlideIndex = productInfoPreviewSwiper.activeIndex;
                const thumbnailActiveSlide = $(productInfoSwiper.slides[activeSlideIndex]);

                thumbnailActiveSlide.next().removeClass('activeSlide');
                thumbnailActiveSlide.addClass('activeSlide');

                productInfoSwiper.slideTo(activeSlideIndex);
            });

            productInfoSwiperSlides.on('click', function () {
                const self = $(this);
                const clickedSlideIndex = self.attr('slide-index');
                const thumbnailActiveSlide = $(productInfoSwiper.slides[clickedSlideIndex]);

                productInfoSwiperSlides.removeClass('activeSlide');
                thumbnailActiveSlide.addClass('activeSlide');
                productInfoSwiper.slideTo(clickedSlideIndex);
                productInfoPreviewSwiper.slideTo(clickedSlideIndex);
            });
        } else if (container === '.product-gallery-swiper') {
            const productGallerySwiper = new Swiper(container, swiperOptions);
            const productInfoPreviewSwiperSlides = $('.product-info__preview-swiper-slide, .product-info-swiper__slider .animatable-link');
            const plusButton = $('.product-info-swiper__slider .animatable-link');
            plusButton.on('click', function () {
                const plusButtonIndex = $(this).parents().attr('slide-index');
                const clickableElement = $('.product-info__preview-swiper-slide[slide-index=' + plusButtonIndex + ']');
                if ($(this).parents().attr('slide-index') === clickableElement.attr('slide-index')) {
                    clickableElement.click();
                    productGallerySwiper.slideTo(clickedSlideIndex);
                };
            })
            productInfoPreviewSwiperSlides.on('click', function () {
                const self = $(this);
                const clickedSlideIndex = self.attr('slide-index');
                productGallerySwiper.slideTo(clickedSlideIndex);
            });
        } else if (container.length > 0) {
            new Swiper(container, swiperOptions);
        }
    }

    smoothScroll(element) {
        let elemAttr = element.attr('href') || element.attr('data-href');
        if (typeof elemAttr !== "undefined") {
            elemAttr = elemAttr.split('#')[1];
        }
        const target = $('main').find('#' + elemAttr);
        if (target.length) {
            $('html, body').stop().animate({
                scrollTop: target.offset().top - 75
            }, 1000);
        }
    }

    static scrollToAnchorOnLoad() {
        const locationHash = window.location.hash;

        if (locationHash) {
            const id = locationHash.split('#')[1];
            document.getElementById(id).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center'
            })
        }
    }

    isElementInViewport(el) {
        el = el[0];

        if (el) {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    }

    static isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
}

export default General;
