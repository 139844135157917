const axios = require('axios').default;

class MarketoFileUpload {
    fileUploadEl = document.querySelectorAll('input[type=file]');
    formData = new FormData();
    filteredFileUrls = [];

    init(MarketoAttachmentFieldID) {
        if (MktoForms2) {
            MktoForms2.whenReady((mktoForm) => {
                // wait for Marketo form to be ready, i.e. loaded. If you only want to run the script for
                // a particular form ID, you can get the form ID using mktoForm.getId(), see
                // https://developers.marketo.com/javascript-api/forms/api-reference/

                const [formEl] = mktoForm.getFormElem();

                if (formEl.closest('.marketo-form--has-attachment')) {
                    // this is the actual script that will add the new file upload field to the form. It also
                    // takes cares of retrieving the file URLs and passing them to Marketo in a hidden field.
                    // The name of the hidden field can be specified below.
                    this.useFileUpload(mktoForm, {
                        // TODO: this is just a placeholder once we created a new field in Marketo
                        hiddenFieldName: MarketoAttachmentFieldID,
                    });
                }
        });
    }
}

getFileInfo(uploadEl, hiddenFieldName, mktoForm) {
    uploadEl.addEventListener('change', async (e) => {

        const { target } = e;
        const { files } = target;

        let fileNames = [];

        files && [...files].forEach((file) => {
            fileNames.push({ 'name': file.name })
        });

        const fileURLsStr = await this.getFileUploadInfo(target).then(res => {
            if (!res) {
                return
            }

            const fileObject = [];
            res.map(file => {
                this.filteredFileUrls.push(file);
                fileObject.push(file)
            })
            mktoForm.setValues({
                [hiddenFieldName]: this.filteredFileUrls.map(fileObj => fileObj.Url).join(';'),
            });

            return fileObject;
        });

        let infoWrapper;
        if (fileURLsStr) {
            const info = Object.assign({}, { files: fileNames }, { 'length': files.length })
            infoWrapper = this.generateFileInfoTextContext(info).infoWrapper;
        }

        if (infoWrapper) {
            uploadEl.appendChild(infoWrapper);
        }

        const self = this;
        const deleteButtons = document.querySelectorAll('.mktoInfoDelete');
        deleteButtons.forEach(function (db) {
            db.addEventListener('click', function (e) {
                const { currentTarget } = e;
                const fileNameEl = currentTarget.closest('.mktoInfoFileNames');
                let fileName = "";
                if (fileNameEl) {
                    fileName = fileNameEl.textContent;
                    fileNameEl.remove();
                }

                self.filteredFileUrls = self.filteredFileUrls.filter(file => file.FileName != fileName);

                mktoForm.setValues({
                    [hiddenFieldName]: self.filteredFileUrls.map(fileObj => fileObj.Url).join(';'),
                });
            })
        }, self)
    })
}

removeFileFromUploadList(file) {

}

generateFileInfoTextContext(info) {
    const randomString = this.createRandomString();
    const infoWrapper = this.createEl("div", {
        className: "mktoInfoWrapper",
        type: "div",
        id: `${randomString}wrapper`
    });

    const fileInfo = info.files && info.files.map((f, index) => {
        const file = this.createEl("span", {
            className: "mktoInfoFileNames",
            type: "span",
            appendTo: infoWrapper,
            textContent: f.name,
            id: `${randomString}files${index}`
        })
        this.createEl("span", {
            className: "mktoInfoDelete",
            type: "span",
            appendTo: file,
            textContent: "",
            id: `${randomString}delete${index}`
        })
    });

    return { infoWrapper };
}

    async getFileUploadInfo(inputEl) {
    let files = Array.from(inputEl.files);
    let formData = new FormData();
    files.forEach((file, index) => {
        formData.append(`fileId${index}`, file, file.name);
    });

    let fileAlreadyExists = [];
    fileAlreadyExists = this.filteredFileUrls.length > 0 ? files.filter(file =>
        this.filteredFileUrls.filter(f => f.FileName === file.name).length) : [];

    if (fileAlreadyExists.length !== 0) {
        return;
    }

    if (inputEl.value && inputEl.value !== '') {
        try {
            const response = await axios.post("/CMSPages/DD/MarketoFileUpload/MarketoFileUpload.ashx", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (res) {
                    return res.data;
                })
                .catch(function (error) {
                    console.log(error)
                })
            return response
        } catch (err) {
            console.log(err)
        }
    }
}

/**
* Creates a somewhat random string; sufficient for simple use-cases
* @returns {String}
*/
createRandomString() {
    return (Math.random() + 1).toString(36).substring(7);
}

/**
 * @param {String} tagName
 * @param { {appendTo: HTMLElement, [key: String]: String }} [attrs]
 * @returns {HTMLElement}
 */
createEl(tagName, { appendTo, className, htmlFor, textContent, type, id, multiple } = {}) {
    const el = Object.assign(document.createElement(tagName), { className, htmlFor, textContent, type, id, multiple });
    if (appendTo) {
        appendTo.appendChild(el);
    }
    return el;
}

/**
* Delay setup of onSubmit function to ensure—at least for most use-cases—that this submit
* callback is fired after all others.
* @param {MktoForms2} mktoForm
* @param {(mktoForm: MktoForms2) => Void} fn
*/
onSubmit(mktoForm, fn) {
    function setup() {
        // in case other form scripts also wait for DOMContentLoaded
        requestAnimationFrame(() => {
            mktoForm.onSubmit(fn);
        });
    }

    if (document.readyState !== "loading") {
        setup();
    } else {
        document.addEventListener("DOMContentLoaded", setup);
    }
}

/**
 * Recreate Marketo default form row structure
 * @param {String} labelName
 * @returns {HTMLElement}
 */
createFileUploadRowEl(labelName = "File Upload") {
    const randomString = this.createRandomString();
    const inputId = `${randomString}fileUpload`;
    const labelId = `${randomString}label`;
    const rowEl = this.createEl("div", {
        className: "mktoAttachmentRow mktoFormRow",
        id: `${randomString}rowEl`
    });
    const colEl = this.createEl("div", {
        className: "mktoFormCol",
        appendTo: rowEl,
        id: `${randomString}colEl`
    });
    const wrapEl = this.createEl("div", {
        className: "mktoAttachmentWrap mktoFieldWrap",
        appendTo: colEl,
        id: `${randomString}wrapEl`
    });
    const fakeInput = this.createEl('span', {
        className: "mktoAttachmentInput",
        appendTo: wrapEl,
        id: `${randomString}span`,
        textContent: window.mktoAddAttachmentButtonText
    });
    const labelEl = this.createEl("label", {
        className: "mktoAttachmentLabel mktoLabel",
        htmlFor: inputId,
        textContent: labelName,
        appendTo: wrapEl,
        id: labelId
    });
    const uploadEl = this.createEl("input", {
        className: "mktoFieldHiddenAttachment",
        type: "file",
        id: inputId,
        appendTo: wrapEl,
        multiple: true
    });
    return { rowEl, uploadEl };
}

/**
 * @param {MktoForms2} mktoForm
 * @param {{ hiddenFieldName: string; labelName?: String }} options
 */
useFileUpload(mktoForm, { hiddenFieldName, labelName } = {}) {
    // `getFormElem` returns a jQuery element, hence we need to "unpack" the jQuery element to
    // get the actual HTML form element.
    const [formEl] = mktoForm.getFormElem();

    // add file upload row element to form
    const buttonRowEl = formEl.querySelector(".mktoButtonRow:not(.mktoButtonRowMultiStepPrev)");
    const { rowEl: uploadRowEl, uploadEl: uploadInputEl } = this.createFileUploadRowEl(labelName);
    formEl.insertBefore(uploadRowEl, buttonRowEl);

    this.getFileInfo(uploadRowEl, hiddenFieldName, mktoForm);

    this.onSubmit(mktoForm, async () => {
        const fieldVals = mktoForm.getValues();
        if (!fieldVals[hiddenFieldName] && fieldVals[hiddenFieldName] !== '') {
            // prevent form submit in order to retrieve file URLs asnyc
            mktoForm.submittable(false);

            // once we retrieve file URLs, pass them to Marketo in a hidden field and submit the
            // form again. Since we set the hidden field before submitting the form, this part of
            // the code will not run again

            let fileURLsStr = "";
            this.filteredFileUrls.filter(f => fileURLsStr += f.Url + " ");

            mktoForm.addHiddenFields({
                [hiddenFieldName]: fileURLsStr,
            });

            mktoForm.submittable(true);
            mktoForm.submit();
        }
    });
}

};

export default MarketoFileUpload;