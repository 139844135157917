import General from '../general/general';

class EventAgenda {
    init() {
        this.initiateSmoothScroll();
    }

    initiateSmoothScroll() {
        const general = new General();
        const scrollToButtons = $('.js-scroll-to');

        scrollToButtons.on('click', (e) => {
            const self = $(e.currentTarget);
            general.smoothScroll(self);
        });
    }
}

export default EventAgenda;
