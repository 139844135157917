import IsInViewport from '../lazy-loading/el-is-in-viewport';
const debounce = require('debounce');

class LazyLoad {
    isInViewport = new IsInViewport();

    // Elements which are lazy loaded
    img = $(`img[img-src], a[img-src], div[img-src], section[img-src]`);

    init() {
        const reinitIsInViewport = this.isInViewport;
        reinitIsInViewport.init(this.img);

        const loadVideo = this.loadVideo;
        loadVideo();

        if ('addEventListener' in window) {
            const img = $(`[mobile-img-src]`); // only for those who has multiple images
            const handleResize = debounce(function () {
                loadVideo();
                reinitIsInViewport.init(img, true);
            }, 100);
            reinitIsInViewport.init(img, true);
            window.addEventListener('resize', handleResize);
        }

        this.hideNullImages();
    }

    loadVideo() {
        const video = $('.bg-image--video video, .bg-image--home-intro video');
        const preloadImage = $('.bg-image:not(".bg-image--video")');
        const preloadImageShouldHide = !$('.bg-image:not(".bg-image--video")').hasClass('swiper-slide');
        const screenXsMax = window.matchMedia('(min-width: 767px)');

        video.each(function () {
            const self = $(this);
            self.removeAttr('preload');
            self.attr('autoplay', 'autoplay');
            if (screenXsMax.matches) {
                if (!self.parent().attr('data-loaded')) {
                    self.trigger('load');
                    self[0].oncanplay = function () {
                        self.parent().attr('data-loaded', 'loaded');
                        self.trigger('play');
                        const promise = self[0].play();
                        if (promise !== undefined) {
                            promise.then(_ => {
                                self.trigger('play');
                                if (preloadImageShouldHide) {
                                    preloadImage.hide();
                                }
                            }).catch(error => {
                                console.log('Autoplay was prevented');
                                console.log(error);
                                self.trigger('play');
                            });
                        }
                    };
                }
            }
            if (!screenXsMax.matches && self.is('.bg-video--is-visible')) {
                self.trigger('play');
                self[0].oncanplay = function () {
                    self.parent().attr('data-loaded', 'loaded');
                    self.trigger('pause');
                    const promise = self[0].pause();
                    if (promise !== undefined) {
                        promise.then(_ => {
                            self.trigger('pause');
                        }).catch(error => {
                            console.log('Pausing was prevented');
                            console.log(error);
                            self.trigger('pause');
                        });
                    }
                };
            };
            if (!screenXsMax.matches && !self.parent().attr('data-loaded')) {
                self.parent().attr('data-loaded', 'loaded');
            }
        });
    }

    hideNullImages() {
        // I'll only cover the image tags, change this comment if the functionality change in the meantime :D
        const img = $('img[img-src]');
        if (img) {
            img.each(function () {
                const self = $(this);
                if (self.attr('img-src') == 'null' || self.attr('img-src') == '') { // Canto didn't return anything
                    self.css({
                        'display': ' none'
                    })
                }
            })
        }
    }
}

export default LazyLoad;