class Calculator {

    init () {
        $(".km-roi__calc div").resizeText({
            windowMinWidth: 520
        });

        this.o = 5;
        this.c = 20;
        this.d = 7.4;
        this.s = 47;

        this.mainElem = $('.km-roi');
        this.employees = this.mainElem.find('.js_km-roi__employees');
        this.salary = this.mainElem.find('.js_km-roi__salary');
        this.hoursPW = this.mainElem.find('.js_km-roi__hoursPerWeek').children().first();
        this.eurosPW = this.mainElem.find('.js_km-roi__eurosPerWeek').children().first();
        this.annum = this.mainElem.find('.js_km-roi__annum').children().first();

        this.addEventHandlers();
        this.calc();
    };

    calc () {

        let self = this;

        $.resizeText.resize();
        self.o = self.employees.val() * self.d;
        self.c = self.o * self.salary.val();
        
        self.hoursPW.text(Math.round(self.o).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")),
        self.eurosPW.text(Math.round(self.c).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")),
        self.annum.text(Math.round(self.c * self.s).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")),
        
        $.resizeText.resize();
    };

    addEventHandlers () {
        this.employees.on('keyup change', this.calc.bind(this));
        this.salary.on('keyup change', this.calc.bind(this));
    };
}

export default Calculator;