import General from '../general/general';

class EventSwiper {
        general = new General;
        eventSwiperOptions = {
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
            1279: {
                slidesPerView: 3
            },
            1024: {
                slidesPerView: 2
            },
            600: {
                slidesPerView: 1
            },
        }
    };

    init() {
        this.swiperInit();
    }

    swiperInit(el) {
        const nextEl = el.next('.swiper-nav-buttons');
        this.eventSwiperOptions.navigation = {
            nextEl: nextEl.find('.event-swiper__btn-next'),
            prevEl: nextEl.find('.event-swiper__btn-prev')
        };
        this.general.initSwiper(el, this.eventSwiperOptions)
    }
}

export default EventSwiper;
