class CustomForm {
    inputElem = $('.custom-form .editing-form-value-cell input, .custom-form .editing-form-value-cell textarea');
    labelCellElemActiveClass = 'label-cell--active';
    errorLabel = $('.custom-form .form-group .EditingFormErrorLabel');

    init() {
        this.onInputFocusChange();
        this.onError();
    }

    onInputFocusChange() {
        this.inputElem.focusin((e) => {
            const self = $(e.currentTarget);
            const labelCellElem = self.parent().parent().prev();
            labelCellElem.addClass(this.labelCellElemActiveClass);
        });

        this.inputElem.focusout((e) => {
            CustomForm.removeActiveClass(this, e);
        });

        this.inputElem.each(function () {
            if (this.value !== "") {
                const labelCellElem = $(this).parents('.tr').find('.FieldLabel');
                labelCellElem.addClass(self.labelCellElemActiveClass);
            }
        });
    }

    onError() {
        if (this.errorLabel.length > 0) {
            this.errorLabel.siblings('.editing-form-label-cell').children().addClass('error-text');
            this.errorLabel.parent().addClass('error-form-group-divider');
        }
    }

    static removeActiveClass(self, event) {
        const elem = $(event.currentTarget);
        const hasValue = elem.val().length > 0;
        if (!hasValue) {
            const labelCellElem = elem.parent().parent().prev();
            labelCellElem.removeClass(self.labelCellElemActiveClass);
        }
    }
}

export default CustomForm;
