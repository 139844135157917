const dynamicsForm = () => {
    const form = document.querySelectorAll('div[data-form-block-id]');

    form.forEach(f => {
        const inputElements = f.querySelectorAll('.lp-form-fieldInput[aria-required=true]');

        inputElements.forEach(ie => {
            ie.addEventListener('blur', function (e) {
                const { currentTarget } = e;
                currentTarget.classList.add('required')
            })
        })
    });

};

export default dynamicsForm;