const accordion = function () {
    $('.accordion').on('click', function () {
        /*  $(this).parents('.accordion').find('.accordion__content').slideToggle(); */
        const accordionContent = $(this).closest('.accordion').find('.accordion__content');
        $(this).closest('.accordion').toggleClass('accordion--open');
        accordionContent.animate({ opacity: 'toggle', height: 'toggle' });
        const accordionImage = accordionContent.find('.generic-content__image');
        if (accordionImage.attr('data-loaded') !== 'loaded') {
            const imgSrc = accordionImage.attr('img-src');
            accordionImage.css('background-image', `url("${imgSrc}")`);
            accordionImage.attr('data-visible', 'visible-no-transition');
            accordionImage.attr('data-loaded', 'loaded');

        }
    });

    $.expr.pseudos.caseInsensitiveContains = function (a, i, m) {
        return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    };

    const accordionSearch = $('.js-accordion-search');
    let delay = null;
    accordionSearch.each(function () {
        const accordionEl = $(this);
        const accordionItemsEl = accordionEl.find('.accordion');
        const searchEl = $(this).find('.accordion-search');
        searchEl.on('keyup', (e) => {
            clearTimeout(delay);
            delay = setTimeout(function () {
                const searchValue = e.currentTarget.value;
                if (searchValue.length > 3) {
                    accordionItemsEl.hide();
                    accordionEl.find('.accordion__title:caseInsensitiveContains(' + searchValue + '), .accordion__content:caseInsensitiveContains(' + searchValue + ')').closest('.accordion').show();
                } else {
                    accordionItemsEl.show();
                }
            }, 50);
        })
    });
};

export default accordion;
