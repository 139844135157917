class ProductInfo {
    init() {
        this.switchImg();
        this.closeModal();
        this.copyTitleForMobile();
    }

    switchImg() {
        const clickableElements = $('.product-info-swiper__slider img, .product-info-swiper__slider .animatable-link, .product-info__preview-video-img-overlay');

        clickableElements.on('click', function () {
            const self = $(this);
            let currentSrc = '';
            const videoClass = 'product-info-swiper__slider--video';
            const isVideo = self.parent().hasClass(videoClass);

            if (isVideo) {
                const videoIframe = $('.custom-modal__body-iframe');
                const videoModal = $('.custom-modal--product-video');
                currentSrc = self.parent().attr('data-video-src');
                videoIframe.attr('src', currentSrc);

                /* On close stop video */
                videoModal.on('hidden.bs.modal', () => {
                    videoIframe.attr('src', '');
                });
            }
        });
    }

    closeModal() {
        $('.modal').on('click', function () {
            $('.modal').modal('hide');
        }).find('.modal-content').click(function() {
            return false;
        });
    }

    copyTitleForMobile() {
        $('.js-copy-title').each(function () {
            const titleContent = $(this).text();
            const mobileTitle = $(`<span class="main-title main-title--product-info-mobile">${titleContent}</span>`);
            mobileTitle.insertBefore($(this).parents('.product-info__wrapper').find('.product-info__main-img'));
        });
    }
}

export default ProductInfo;
