class OverviewSubsection {
    init() {
        this.calculateMaxHeight();
    }

    calculateMaxHeight() {
        const overviewSubsectionElem = $('.overview-subsection__modal-content');
        overviewSubsectionElem.each(function () {
            const elem = $(this);
            elem.removeAttr('style');
            const elemParentIsVisible = elem.parent(':visible');
            if (elemParentIsVisible.length === 0) {
                elem.parent().css({
                    visibility: 'hidden',
                    display: 'block',
                    position: 'absolute',
                    top: '-10000px',
                    left: '-10000px'
                })
            }

            let elemHeight = elem.innerHeight();
            const elemPosition = elem.position();
            const documentHeight = document.documentElement.clientHeight;
            const nav = document.querySelector('nav');
            const navHeight = nav ? nav.clientHeight : 0;

            if (elemParentIsVisible.length === 0) {
                elem.parent().removeAttr('style')
            }

            // element is beneath the navigation

            if (elemPosition.top < navHeight) {
                elem.css({
                    top: `${navHeight + 10}px`,
                    transform: 'translate(-50%, 0)',
                    maxHeight: `calc(100vh - ${navHeight + 20}px)`
                })
            } else {
                elem.removeAttr('style')
            }

            if (elemHeight + (navHeight * 2) > documentHeight) {
                elem.css({
                    top: `${navHeight + 10}px`,
                    transform: 'translate(-50%, 0)'
                })
            }
        })
    }
}

export default OverviewSubsection;