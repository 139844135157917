const debounce = require('debounce');

const handleResponsiveBgImagesForSmallScreens = () => {

    function calculateImageSize(windowWidth, naturalWidth, naturalHeight) {
        let aspectRatio = 1;
        if (naturalWidth > windowWidth) {
            aspectRatio = windowWidth / naturalWidth;
        }
        const newHeight = naturalHeight * aspectRatio;;
        return { backgroundSize: `auto ${newHeight}px`, height: `${newHeight}px`, width: `${windowWidth}`, naturalWidth: `${naturalWidth}`, naturalHeight: `${naturalHeight}` }
    }

    function getBackgroundSize(windowWidth, imageHref) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageHref;

            img.addEventListener('load', () => {
                const { naturalWidth: width, naturalHeight: height } = img;
                const calculatedValues = calculateImageSize(windowWidth, width, height);
                resolve(calculatedValues)
            });
            img.addEventListener('error', (err) => reject(err));
        })
    }

    function handleImageSize() {
        const bgImageElem = document.querySelectorAll('.js-mobile-resize');
        const isMobile = window.matchMedia('(max-width: 768px)').matches;
        const windowWidth = window.outerWidth;

        if (isMobile) {
            bgImageElem.forEach((image) => {
                // const textElem = image.nextElementSibling;
                // const textElemHasClass= textElem ? textElem.classList.contains('intro-header__txt'): false;
                // const textElemHeight = textElemHasClass ? textElem.clientHeight + textElem.offsetTop * 2 : 0;

                if (image.dataset.loaded) {
                    const imageInlineStyle = image.getAttribute('style');
                    if (imageInlineStyle) {
                        const arrStyles = imageInlineStyle.split(';');
                        const regex = new RegExp(/(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/);
                        let imageHref = "";
                        [...arrStyles].forEach(style => {
                            imageHref = style.match(regex) && style.match(regex)[0];
                            if (imageHref) {
                                if (image.dataset.naturalWidth && image.dataset.naturalHeight) {
                                    const values = calculateImageSize(windowWidth, image.dataset.naturalWidth, image.dataset.naturalHeight);
                                    image.style.backgroundSize = values.backgroundSize;
                                    image.style.height = values.height;
                                    image.dataset.isMobile = isMobile.toString();
                                }
                                else { // is first load
                                    getBackgroundSize(windowWidth, imageHref).then(imageSize => {
                                        image.style.backgroundSize = imageSize.backgroundSize;
                                        image.style.height = imageSize.height;
                                        image.dataset.naturalHeight = imageSize.naturalHeight;
                                        image.dataset.naturalWidth = imageSize.naturalWidth;
                                        image.dataset.isMobile = isMobile.toString();
                                    }).catch(e => {
                                        console.log(e);
                                    });
                                }



                            }
                        });
                    }
                }
            });
        } else {
            bgImageElem.forEach(image => {
                if (image.dataset.isMobile) {
                    image.style.backgroundSize = "";
                    image.style.height = "";
                }
            })
        }
    }

    if ('addEventListener' in window) {
        const handleResize = debounce(function () {
            handleImageSize();
        }, 25);
        window.addEventListener('resize', handleResize);
    }
    handleImageSize();
};

export default handleResponsiveBgImagesForSmallScreens;
