import './jquery-ui';

class ClimateSlider{
    
    // helper functions
    refreshComparison (val) {
        var factor = 1000; // multiply val by this factor for kg (1) or tons (1000) CO2e
        val = val * factor;

        var car = 0.32199814; // kg CO2 per vehicle km
        var wash = 0.93; // kg CO2e per washing machine run 
        var tree = 12.50; // kg CO2e of carbon fixation of a beech tree per year
        var shoe = 16.70; // kg CO2 per pair of running shoes
        var beef = 12.29; // kg CO2e per kg of beef production
        var ice = 1000/3; // kg CO2 per square meter of arctic summer ice
        var flight = 839.90; // kg CO2e per economy flight from LHR to JFK
        var citizen = 7000.00; // kg CO2e per World citizen per year

        $('.cp-car').text(this.calcAndRound(val, car));
        $('.cp-wash').text(this.calcAndRound(val, wash));
        $('.cp-tree').text(this.calcAndRound(val, tree));
        $('.cp-shoe').text(this.calcAndRound(val, shoe));
        $('.cp-beef').text(this.calcAndRound(val, beef));
        $('.cp-ice').text(this.calcAndRound(val, ice));
        $('.cp-flight').text(this.calcAndRound(val, flight));
        $('.cp-citizen').text(this.calcAndRound(val, citizen));
    }

    formatNumber(n) {
        var s = ('' + n).split('.'); // add thousand separator to number
        var selectedSeparator = $('.data-separator').attr('data-separator');
        var sep = selectedSeparator ? selectedSeparator : ',';
        var temp = s[0].split('').reverse().join('').match(/\d{1,3}/gi);
        if (temp !== null && temp !== undefined) {
            s[0] = temp.join(sep).split('').reverse().join('');
        }
        return (s.join(','));
    }

    calcAndRound(val, compareval) {
        return this.formatNumber(Math.round(val / compareval)); // round to integer
    }
    
    init(){
        const slider = $('.cp-slider').slider({
            value: 1, // start value of the slider in tons CO2e
            min: 1, // min value of the slider in tons CO2e
            max: 2000, // max value of the slider in tons CO2e
            step: 1,
            create: function() {
                $('.cp-slider-handle-value').text($(this).slider('value'));
            }
        });
        
        slider.on('slide', function (event, ui){
            $('.cp-slider-handle-value').text(this.formatNumber(ui.value));
        
            var emissions = ui.value;
            this.refreshComparison(emissions);
        }.bind(this));

        var initialvalue = $('.cp-slider').slider('value');

        var emissions = initialvalue;
        this.refreshComparison(emissions);  
    };
}

export default ClimateSlider;