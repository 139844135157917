import General from '../general/general';

class FullWidthSlider {
    general = new General;
    fullWidthSliderOptions = {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        pagination: {
            el: '.full-width-slider__pagination',
            bulletClass: 'swiper-bullet',
            bulletActiveClass: 'swiper-bullet--active',
        }
    };

    init() {
    }

    swiperInit(el) {
        const nextEl = el.next('.swiper-nav-buttons');
        this.fullWidthSliderOptions.navigation = {
            nextEl: nextEl.find('.swiper-button-next'),
            prevEl: nextEl.find('.swiper-button-prev')
        };
        this.general.initSwiper(el, this.fullWidthSliderOptions)
    }
}

export default FullWidthSlider;
