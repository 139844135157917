class ProductFunctionalities {

    init () {
        const element = $('.product-functionalities');
        
        if (element) {
            const $loadMoreBtn = $('a.js-pf-load-more');
            const $elements = $('.product-functionalities__outer-wrapper > div.js-pf-block'); 
            const increaseBy = 2;

            this.registerLoadMoreHandler($loadMoreBtn, increaseBy, $elements);
            this.displayElements(2, $elements, $loadMoreBtn);
        }
    }

    registerLoadMoreHandler ($buttonElem, showMoreBy, $elems) {
        const that = this;
        $buttonElem.on('click', function() {
            that.displayElements(showMoreBy, $elems, this);
        });
    }

    displayElements(showMore, $elements, $loadMoreBtn) {
        var hiddenElems = this.getCurrentlyHiddenElements($elements);
        for(var i = 0; i < showMore; i++) {
            $(hiddenElems[i]).removeClass('d-none');
        }

        if (hiddenElems.length <= showMore)
        {
            $($loadMoreBtn).addClass('d-none');
        }
    }

    getCurrentlyHiddenElements($shownElements) {
        return $shownElements.filter('.d-none');
    }
}

export default ProductFunctionalities;